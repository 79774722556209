import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Switch from '@mui/material/Switch';
import './CAASubmissions.css';

const CAASubmissions = () => {
  const [linkSubmissions, setLinkSubmissions] = useState([]);
  const [giftedSubmissions, setGiftedSubmissions] = useState([]);
  const [activeTab, setActiveTab] = useState('linkSubmissions');
  const [statusFilter, setStatusFilter] = useState('all');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) navigate('/login');
    });
    return unsubscribe;
  }, [navigate]);

  useEffect(() => {
    const checkSubscription = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const userDocRef = collection(firestore, 'users', user.uid, 'subscriptions');
      const q = query(userDocRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(q);

      setIsSubscribed(!querySnapshot.empty);
    };

    checkSubscription();
  }, []);

  useEffect(() => {
    if (isSubscribed && activeTab === 'linkSubmissions') {
      fetchLinkSubmissions();
    } else {
      fetchGiftedSubmissions();
    }
  }, [isSubscribed, activeTab, statusFilter, searchTerm]);

  const fetchLinkSubmissions = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const submissionsRef = collection(firestore, `users/${user.uid}/submissions`);
      const submissionsSnapshot = await getDocs(submissionsRef);
      const submissionsData = submissionsSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((submission) => submission.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          submission.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          submission.email.toLowerCase().includes(searchTerm.toLowerCase()));

      setLinkSubmissions(submissionsData);
    } catch (error) {
      console.error("Error fetching link submissions:", error);
    }
  };

  const fetchGiftedSubmissions = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      let q = query(
        collection(firestore, 'Submissions'),
        where('caaAgent', '==', user.uid)
      );

      if (statusFilter !== 'all') {
        q = query(q, where('status', '==', statusFilter.toLowerCase()));
      }

      const querySnapshot = await getDocs(q);
      const submissionsData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((submission) => submission.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          submission.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          submission.email.toLowerCase().includes(searchTerm.toLowerCase()));

      setGiftedSubmissions(submissionsData);
    } catch (error) {
      console.error("Error fetching gifted submissions:", error);
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggleChange = () => {
    setActiveTab((prev) => (prev === 'linkSubmissions' ? 'giftedSubmissions' : 'linkSubmissions'));
  };

  const renderTable = (submissions) => (
    <table className="submissions-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {submissions.length > 0 ? (
          submissions.map((submission) => (
            <tr key={submission.id} onClick={() => navigate(`/CAA/submission/${submission.id}`)}>
              <td>{submission.id}</td>
              <td>{submission.firstName}</td>
              <td>{submission.lastName}</td>
              <td>{submission.email}</td>
              <td>{submission.status}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="no-submissions">No applications found</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <div className="caa-submissions">
      <div className="header">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-bar"
        />
        {isSubscribed && (
          <div className="toggle-container">
            <span>{activeTab === 'linkSubmissions' ? 'Link Submissions' : 'Gifted Submissions'}</span>
            <Switch
              checked={activeTab === 'giftedSubmissions'}
              onChange={handleToggleChange}
              color="primary"
            />
          </div>
        )}
      </div>
      {isSubscribed && activeTab === 'giftedSubmissions' && (
        <div className="filter">
          <label htmlFor="status-filter">Filter by status: </label>
          <select id="status-filter" value={statusFilter} onChange={handleStatusFilterChange}>
            <option value="all">All</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="declined">Declined</option>
            <option value="mailed">Mailed</option>
          </select>
        </div>
      )}
      <div className="table-container">
        {activeTab === 'linkSubmissions' && isSubscribed ? renderTable(linkSubmissions) : renderTable(giftedSubmissions)}
      </div>
    </div>
  );
};

export default CAASubmissions;
