import React, { createContext, useEffect, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const defaultData = {
    document_image: null,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    countryOfBirth: '', 
    gender: '', 
    countriesOfCitizenship: [],
    hasForeignTaxID: false, 
    foreignTaxID: '', 
    hasUSVisa: false, 
    visaNumber: '', 
    visaExpirationDate: '',
    residentStreet: '',
    residentCity: '',
    residentState: '',
    residentZipCode: '',
    foreignStreet: '',
    foreignCity: '',
    foreignCountry: '',
    foreignPostalCode: '',
    applicationType: '',
    reason: '',
    otherReason: '',
    residentOrSpouseSsnITIN: '',
    relationship: '',
    residentOrSpouseName: '',
    itinIrsnType: '',
    itinIrsnNumber: '',
    entryDate: '',
    verificationComplete: false,
    passportIssuer: '',
    passportNumber: '',
    expiryDate: '',
    taxFilingUpload: null,
    documentUpload: null,
    schoolRecordUpload: null,
    signature: '',
    status: 'pending',
    shippingStreet: '',
    shippingCity: '',
    shippingState: '',
    shippingZipCode: '',
    shippingCountry: '',
    legalNameOfEntity: '',
    tradeNameOfBusiness: '',
    businessStreet: '',
    businessCity: '',
    businessState:'',
    businessZipCode:'',
    businessMailingStreet:'',
    businessMailingCity: '',
    businessMailingState:'',
    businessMailingZipCode:'',
    entityType: '',
    secret: '',
    soleProprietor: '',
    partnership: '',
    numberOfMembers: '',
    businessStartDate: '',
    closingMonthOfAccountingYear: '',
    numberOfEmployees: '',
    primaryBusinessActivity: '',
    specificBusinessDetails: '',
    einSsnOrItin: '',
    reasonOther: '',
    primaryBusinessActivityOther: '',
    haveSsnOrItin: '',
    alreadyHaveEIN: false,
    einNumber: '',
    // Third-Party Designee Information
    isThirdPartyDesignee: false,
    thirdPartyDesigneeInfo: {
      name: '',
      ssnOrItin: '',
      contactDetails: ''
    },
  };
  
  const [formData, setFormData] = useState(() => {
    const storedData = localStorage.getItem('formData');
    if (storedData) {
      // Merge stored data with default data
      return { ...defaultData, ...JSON.parse(storedData) };
    } else {
      return defaultData;
    }
  });

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
