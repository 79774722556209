import React from "react";
import { FormProvider } from "./FormContext";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AppRoutes from "./AppRoutes";

import enTranslations from "./locales/en/translation.json";
import esTranslations from "./locales/es/translation.json";
import cnTranslations from "./locales/cn/translation.json";
import fnTranslations from "./locales/fn/translation.json";
import hnTranslations from "./locales/hn/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    es: {
      translation: esTranslations,
    },
    cn: {
      translation: cnTranslations,
    },
    fn: {
      translation: fnTranslations,
    },
    hn: {
      translation: hnTranslations,
    }
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <div className="App">
      <FormProvider>
        <AppRoutes />
      </FormProvider>
    </div>
  );
}

export default App;
