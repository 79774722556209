// AdminHub.jsx
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, writeBatch } from 'firebase/firestore';
import './Admin.css';

const AdminHub = () => {
  const [submissions, setSubmissions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'paymentStatus', direction: 'asc' });
  const navigate = useNavigate();
  const firestore = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) navigate('/login');
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    fetchData();
  }, [firestore]);

  const fetchData = async () => {
    const submissionsRef = collection(firestore, 'Submissions');
    const querySnapshot = await getDocs(submissionsRef);
    const submissionsData = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
      const submission = docSnapshot.data();
      let caaAgent = 'Unassigned';
      if (submission.caaAgent) {
        const caaAgentDoc = await getDoc(doc(firestore, 'users', submission.caaAgent));
        const caaAgentData = caaAgentDoc.data();
        if (caaAgentData && caaAgentData.username) {
          caaAgent = caaAgentData.username;
        }
      }
      return {
        id: docSnapshot.id,
        firstName: submission.firstName,
        lastName: submission.lastName,
        email: submission.email,
        caaAgent: caaAgent,
        paymentStatus: submission.PaymentConfirmation ? 'Paid' : 'Unpaid'
      };
    }));

    setSubmissions(submissionsData);
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0 || !window.confirm('Are you sure you want to delete the selected submissions?')) {
      return;
    }
  
    const db = getFirestore();
    const batch = writeBatch(db);
  
    selectedRows.forEach((rowId) => {
      const docRef = doc(db, 'Submissions', rowId);
      batch.delete(docRef);
    });
  
    try {
      await batch.commit();
      setSelectAll(false);
      setSelectedRows([]);
      fetchData();
    } catch (error) {
      console.error('Error deleting submissions:', error);
    }
  };
  

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows(submissions.map(row => row.id));
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };

  const handleRowSelect = (id) => {
    setSelectedRows(prev => 
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedSubmissions = [...submissions].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredSubmissions = sortedSubmissions.filter(submission => 
    submission.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    submission.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    submission.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-hub">
      <header className="admin-header">
        <h1>Waitlist</h1>
      </header>

      <div className="admin-actions">
        {selectedRows.length > 0 && (
          <button onClick={handleDelete} className="delete-btn">Delete Selected</button>
        )}
        <input 
          type="text" 
          placeholder="Search by name or email..." 
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      <table className="submissions-table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
            </th>
            <th onClick={() => handleSort('firstName')}>Name</th>
            <th onClick={() => handleSort('email')}>Email</th>
            <th onClick={() => handleSort('caaAgent')}>CAA Agent</th>
            <th onClick={() => handleSort('paymentStatus')}>Payment Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubmissions.map((submission) => (
            <tr key={submission.id} onClick={() => navigate(`/admin/submission/${submission.id}`)}>
              <td>
                <input 
                  type="checkbox" 
                  checked={selectedRows.includes(submission.id)} 
                  onChange={() => handleRowSelect(submission.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </td>
              <td>{`${submission.firstName} ${submission.lastName}`}</td>
              <td>{submission.email}</td>
              <td>{submission.caaAgent}</td>
              <td>{submission.paymentStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminHub;
