import React, { useState } from 'react';
import NotificationDropdown from './NotificationDropdown'; // Component for showing the dropdown
import NotificationSVG from '../../assets/icons/notification.svg'; // Assuming the path to your SVG

const NotificationIcon = ({ notifications }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="notification-icon-container" onClick={toggleDropdown}>
      <img src={NotificationSVG} alt="Notifications" className="notification-icon" />
      {notifications.length > 0 && <span className="notification-dot"></span>}
      {showDropdown && <NotificationDropdown notifications={notifications} />}
    </div>
  );
};

export default NotificationIcon;
