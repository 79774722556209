// ActionModal.js
import React from 'react';
import './ActionModal.css';

const ActionModal = ({ open, onClose, onAssignCAA, onDeleteCustomer, onViewApplication }) => {
  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Choose an action</h2>
        <div className="modal-actions">
          <button className="modal-button" onClick={onAssignCAA}>Assign CAA Agent</button>
          <button className="modal-button" onClick={onDeleteCustomer}>Delete Customer</button>
          <button className="modal-button" onClick={onViewApplication}>View Application</button>
        </div>
        <button className="modal-close" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ActionModal;