import React, { useState, useEffect, useContext } from 'react';
import { TextField, Autocomplete, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const CountryOfBirth = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [countryOfBirth, setCountryOfBirth] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [fieldError, setFieldError] = useState('');

  const fetchCountryOptions = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const countries = await response.json();
      let options = countries.map((country) => ({
        key: country.cca2,
        value: country.cca2,
        label: country.name.common,
      }));
  
      options = options.filter(option => option.label !== 'United States');
  
      setCountryOptions(options);
    } catch (error) {
      console.log('Error fetching country options:', error);
    }
  };

  useEffect(() => {
    fetchCountryOptions();
  }, []);

  useEffect(() => {
    setCountryOfBirth(formData.countryOfBirth || null);
  }, [formData]);

  const handleCountryOfBirthChange = (_, value) => {
    const countryName = value ? value.label : '';
    setCountryOfBirth(countryName);
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryOfBirth: countryName,
    }));
  };

  const nextPage = () => {
    if (!countryOfBirth) {
      setFieldError(t('countryOfBirthError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('basicInfo')}</span>
        <Tooltip title={t('infoBasicInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div className="sub-sub-title">{t('birthPlace')}</div>
      <Autocomplete
        id="country-select"
        style={{ width: '100%' }}
        options={countryOptions}
        autoHighlight
        getOptionLabel={(option) => option.label}
        value={countryOptions.find((country) => country.label === countryOfBirth) || null}
        onChange={handleCountryOfBirthChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('countryOfBirth')}
            variant="standard"
            error={Boolean(fieldError)}
            helperText={fieldError}
          />
        )}
      />
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default CountryOfBirth;
