import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { FormContext } from '../FormContext';
import { firestore } from '../firebase'; // Ensure this is correctly initialized
import Header from './components/Header';
import Footer from './components/Footer';
import RapidW7 from './customer/RapidW7';
import ReviewCAA from './customer/reviewCAA';
import CustomerMessages from './customer/Messages';
import './Form.css'; // Make sure the path is correct

const PaymentConfirmationPage = () => {
  const { t } = useTranslation();
  const [docId, setDocId] = useState('');
  const { formData, setFormData } = useContext(FormContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const docIdFromUrl = urlParams.get('docId');
    setDocId(docIdFromUrl);

    const fetchSubmission = async (docId) => {
      const docRef = doc(firestore, 'Submissions', docId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setFormData(docSnap.data());
      }
    };

    fetchSubmission(docIdFromUrl);
  }, [setFormData]);

  useEffect(() => {
    if (docId) {
      const docRef = doc(firestore, 'Submissions', docId);
      updateDoc(docRef, {
        paymentConfirmation: true,
        secret: window.location.href
      }).catch(error => {
        console.error('Error updating Firestore document:', error);
      });
    }
  }, [docId]);

  return (
    <div>
      <Header />
      <div className="success">
        {formData.serviceType === 'rapidW7Completion' ? (
          <RapidW7 formData={formData} />
        ) : (
          <div className="standard-success">
            {/* Existing success page content for other service types */}
            <h1 className="page-title">{t('paymentConfirmed')}</h1>
            <div className='top-side'>
              <Chip
                label="Receipt Link"
                onClick={() => navigator.clipboard.writeText(window.location.href)}
                variant="outlined"
              />
              {formData.tracking ? (
                <Chip
                  label={`Tracking Number: ${formData.tracking}`}
                  variant="outlined"
                />
              ) : (
                <Chip label={`Submission ID: ${docId}`} variant="outlined" />
              )}
              {formData.caaAgent ? (
                <Chip
                  avatar={<Avatar alt="CAA Agent" src={formData.caaAgent.profilePictureUrl || '/default-profile.png'} />}
                  label={`${formData.caaAgent.firstName} ${formData.caaAgent.lastName}`}
                  variant="outlined"
                  component={Link}
                  to={`/profile/${formData.caaAgent.username}`}
                  clickable
                />
              ) : (
                <Chip label="CAA Agent: Unassigned" variant="outlined" />
              )}
            </div>
            <div className="content-container">
              <div className="column-one">
                {formData.tracking ? (
                  <ReviewCAA caaAgentId={formData.caaAgent?.id} submissionId={docId} />
                ) : (
                  <>
                    <p className="confirmation-text">{t('paymentReceived')}</p>
                    <div className="success-sub-title">{t('verifyIdentity')}</div>
                    <div id='veriff-root' className="verification-section"></div>
                    <hr className="divider" />
                    <div className="success-sub-title">{t('uploadMostRecentTaxFilings')}</div>
                    <div className="upload-section">
                      <input
                        type="file"
                        accept="application/pdf"
                        name="taxFilingUpload"
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const storage = getStorage();
                          const storageRef = ref(storage, `taxreturn_uploads/${file.name}`);
                          const uploadTask = uploadBytesResumable(storageRef, file);
                          await uploadTask;
                          const downloadURL = await getDownloadURL(storageRef);
                          setFormData(prev => ({
                            ...prev,
                            taxFilingUpload: {
                              name: file.name,
                              url: downloadURL,
                            },
                          }));
                          const docRef = doc(firestore, 'Submissions', docId);
                          await updateDoc(docRef, {
                            taxFilingUpload: {
                              name: file.name,
                              url: downloadURL,
                            },
                          });
                        }}
                        className='input-file'
                      />
                      <a className="help-link" href="https://www.taxact.com/" target="_blank" rel="noopener noreferrer">
                        {t('needTaxCompletion')}
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="column-two">
                <CustomerMessages caaAgentId={formData.caaAgent?.id} submissionId={docId} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default PaymentConfirmationPage;
