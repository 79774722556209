import React, { useState, useContext } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { FormContext } from '../../../FormContext';
import { useTranslation } from 'react-i18next';

const PhoneNumber = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || '');
  const [error, setError] = useState('');

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    // Clear error message when user is typing.
    if (error) setError('');
    setFormData({ ...formData, phoneNumber: value });
  };

  const handleNext = () => {
    // Check if the phone number is valid when the "Continue" button is clicked.
    if (!phoneNumber || phoneNumber.length < 8) { // Assuming a minimum length for phone numbers
      setError(t('phoneNumberInvalid'));
    } else {
      setError('');
      onNext();
    }
  };

  return (
    <div className="phone-number-page">
      <div className="header">
        <span className="sub-title">{t('phoneNumber')}</span>
      </div>
      <PhoneInput
        international
        defaultCountry="US"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      {error && <p className="error-message">{error}</p>}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={handleNext} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default PhoneNumber;