import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import CAASideNav from '../pages/components/CAAHeader';
import DashboardHeader from '../pages/components/DashboardHeader';
import useFetchNotifications from '../hooks/useFetchNotifications';
import './CAAHub.css';
import { getAuth } from 'firebase/auth';

const CAAHub = () => {
    const location = useLocation();
    const notifications = useFetchNotifications();
    const auth = getAuth();
    const user = auth.currentUser;
    let title;

    console.log("CAAHub rendered");
    console.log("Current location:", location.pathname);
    console.log("Current user:", user);

    if (!user) {
        console.log("No user is logged in.");
        return null;
    }

    switch (location.pathname) {
        case '/CAA':
            title = 'CAA Dashboard';
            break;
        case '/CAA/submissions':
            title = 'Submissions';
            break;
        case '/CAA/messages':
            title = 'Messages';
            break;
        case '/CAA/settings':
            title = 'Settings';
            break;
        case '/CAA/profile':
            title = 'Your Profile';
            break;
        case '/CAA/premium':
            title = 'Premium';
            break;
        default:
            title = 'Dashboard';
    }

    return (
        <div className="dashboard">
            <CAASideNav />
            <div className="dashboard-content">
                <DashboardHeader title={title} user={user} notifications={notifications} />
                <div className="content-body">
                    <Outlet /> {/* This will be the scrollable content */}
                </div>
            </div>
        </div>
    );
}

export default CAAHub;
