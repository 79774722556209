import React from 'react';
import { jellyTriangle } from 'ldrs'
import './Loader.css';

jellyTriangle.register()

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <l-jelly-triangle
          size="30"
          speed="1.75" 
          color="#00C6FF"
        ></l-jelly-triangle>
      </div>
    </div>
  );
};

export default Loader;