import React, { useEffect, useState, useMemo } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import printerIcon from '../../assets/icons/print.svg';
import copyIcon from '../../assets/icons/copy.svg';
import './RapidW7.css';
import confetti from 'canvas-confetti';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const RapidW7 = ({ formData }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [secretLink, setSecretLink] = useState(window.location.href);

  const options = useMemo(() => ({
    workerSrc: `${process.env.PUBLIC_URL}/pdf.worker.mjs`,
  }), []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year.slice(-2)}`;
  };

  useEffect(() => {
    const generatePDF = async () => {
      try {
        const formUrl = '/w7.pdf';
        const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(formPdfBytes);
        const form = pdfDoc.getForm();

        // Field mapping
        const fieldMapping = {
          firstName: 'topmostSubform[0].Page1[0].f1_7[0]',
          lastName: 'topmostSubform[0].Page1[0].f1_9[0]',
          residentStreet: 'topmostSubform[0].Page1[0].f1_13[0]',
          residentCity: 'topmostSubform[0].Page1[0].f1_14[0]',
          residentState: 'topmostSubform[0].Page1[0].f1_14[0]',
          residentZipCode: 'topmostSubform[0].Page1[0].f1_14[0]',
          foreignStreet: 'topmostSubform[0].Page1[0].f1_15[0]',
          foreignCity: 'topmostSubform[0].Page1[0].f1_16[0]',
          foreignCountry: 'topmostSubform[0].Page1[0].f1_16[0]',
          foreignPostalCode: 'topmostSubform[0].Page1[0].f1_16[0]',
          dateOfBirth: 'topmostSubform[0].Page1[0].f1_17[0]',
          countryOfBirth: 'topmostSubform[0].Page1[0].f1_18[0]',
          relationshipToUSCitizenResidentAlien: 'topmostSubform[0].Page1[0].f1_2[0]',
          residentOrSpouseSsnITINOfUSCitizenResidentAli: 'topmostSubform[0].Page1[0].f1_3[0]',
          otherReason: 'topmostSubform[0].Page1[0].f1_4[0]',
          entryDate: 'topmostSubform[0].Page1[0].f1_27[0]',
          expiryDate: 'topmostSubform[0].Page1[0].f1_26[0]',
          passportNumber: 'topmostSubform[0].Page1[0].f1_25[0]',
          passportIssuer: 'topmostSubform[0].Page1[0].f1_24[0]',
          foreignTaxID: 'topmostSubform[0].Page1[0].f1_21[0]',
        };

        // Checkboxes mapping
        const checkboxMapping = {
          applicationType: {
            new: 'topmostSubform[0].Page1[0].ApplicationType[0].c1_1[0]',
            renew: 'topmostSubform[0].Page1[0].ApplicationType[0].c1_1[1]',
          },
          reason: {
            nonUSResident: 'topmostSubform[0].Page1[0].c1_3[0]',
            usResidentAlienTaxReturn: 'topmostSubform[0].Page1[0].c1_4[0]',
            dependentOfUSCitizenResidentAlien: 'topmostSubform[0].Page1[0].c1_5[0]',
            spouseOfUSCitizenResidentAlien: 'topmostSubform[0].Page1[0].c1_6[0]',
            other: 'topmostSubform[0].Page1[0].c1_9[0]',
          },
          gender: {
            male: 'topmostSubform[0].Page1[0].c1_10[0]',
            female: 'topmostSubform[0].Page1[0].c1_10[1]',
          },
        };

        // Always checked checkbox
        const alwaysCheckedCheckbox = 'topmostSubform[0].Page1[0].c1_11[0]';

        // Fill text fields
        Object.entries(fieldMapping).forEach(([dataKey, fieldName]) => {
          if (formData[dataKey]) {
            try {
              const field = form.getTextField(fieldName);
              if (field) {
                let textToSet = formData[dataKey];
                
                // Format dates
                if (dataKey === 'dateOfBirth' || dataKey === 'entryDate') {
                  textToSet = formatDate(textToSet);
                }

                // Combine city, state, and zip code
                if (dataKey === 'residentCity' || dataKey === 'residentState' || dataKey === 'residentZipCode') {
                  textToSet = `${formData.residentCity}, ${formData.residentState}, ${formData.residentZipCode}`;
                }

                if (dataKey === 'foreignCity' || dataKey === 'foreignCountry' || dataKey === 'foreignPostalCode') {
                  textToSet = `${formData.foreignCity}, ${formData.foreignCountry}, ${formData.foreignPostalCode}`;
                }

                const maxLength = field.getMaxLength();
                if (maxLength && textToSet.length > maxLength) {
                  field.setText(textToSet.substring(0, maxLength));
                } else {
                  field.setText(textToSet);
                }
              }
            } catch (error) {
              console.error(`Error setting text for field ${fieldName}:`, error);
            }
          }
        });

        // Fill checkboxes
        Object.entries(checkboxMapping).forEach(([dataKey, options]) => {
          if (formData[dataKey]) {
            const fieldName = options[formData[dataKey]];
            if (fieldName) {
              try {
                const field = form.getCheckBox(fieldName);
                if (field) {
                  field.check();
                }
              } catch (error) {
                console.error(`Error setting checkbox for field ${fieldName}:`, error);
              }
            }
          }
        });

        // Check the always-checked checkbox
        try {
          const field = form.getCheckBox(alwaysCheckedCheckbox);
          if (field) {
            field.check();
          }
        } catch (error) {
          console.error(`Error setting always-checked checkbox:`, error);
        }

        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      } catch (error) {
        console.error("Error generating PDF: ", error);
      }
    };

    generatePDF();

    // Run confetti animation
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    // Cleanup the effect to avoid memory leaks
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
    };
  }, [formData]);

  const handlePrint = () => {
    if (pdfUrl) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = 'none';
      iframe.style.top = '0';
      iframe.style.left = '0';
      iframe.src = pdfUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow.print();
        document.body.removeChild(iframe); // Clean up after printing
      };
    }
  };

  const handleEdit = () => {
    // Add logic to enable live editing if necessary
  };

  const handleCopySecret = () => {
    navigator.clipboard.writeText(secretLink)
      .then(() => {
        alert('Secret link copied to clipboard!');
      })
      .catch(error => {
        console.error('Error copying secret link:', error);
      });
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="rapid-w7">
      <h1 className="confirmation-title">Purchase Confirmed!</h1>
      <p className="confirmation-message">Thank you for your purchase! Here is your completed W-7 form. You can print it or edit it as you see fit. Below is your secret link to access this form anytime.</p>
      <div className="secret-link-container">
        <div className="secret-link">
          <span>{secretLink}</span>
          <button className="copy-button" onClick={handleCopySecret}>
            <img src={copyIcon} alt="Copy" /> Copy
          </button>
        </div>
      </div>
      <div className="pdf-actions">
        <button className="print-button" onClick={handlePrint}>
          <img src={printerIcon} alt="Print" /> Print
        </button>
      </div>
      {pdfUrl && (
        <div className="pdf-viewer">
          <Document 
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} />
            ))}
          </Document>
        </div>
      )}
    </div>
  );
};

export default RapidW7;
