import React from 'react';
import '../Form.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../logo/full-logo.png';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className="terms">
      <img src={logo} alt="ITIN Express Logo" className="logo-big" />
      <div className="terms-container">
        <h1 className="page-title">Privacy Policy</h1>
        <div className="scrollable-text">

          <h2 className="sub-terms">Details of the Responsible Body</h2>
          <p>
          The body responsible for handling personal data on itin.support is Itin Support LLC, with Tax ID number B12345678, and its registered office at [Address]. Itin Support LLC is a private entity dedicated to the management and support of individual users and businesses in the fast, secure, and quality processing of documentation related to Individual Taxpayer Identification Numbers (ITINs).
          </p>
          <p>
          The responsible body is committed to complying with the provisions of the applicable data protection laws, including the (EU) Regulation 2016/679 of the European Parliament and of the Council of 27th April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data (hereafter referred to as "GDPR") and national legislation regarding the protection of personal data. Itin Support LLC also complies with other relevant laws, including the provisions of the Law on Information Society and Electronic Commerce Services. The responsible body will always ask for your consent when handling your data for marketing purposes.
          </p>

          <h2 className="sub-terms">Reasons for the Handling of Personal Data</h2>
          <p>
          When using the itin.support website and services, it is necessary to provide certain personal details to complete the ITIN application process and access the services offered. Failure to provide the requested personal details or not accepting this Privacy Policy may prevent you from subscribing, registering, or participating in the ITIN application process. Please note that when our services are directed towards minors, their parents or guardians are responsible for providing the necessary consent and supervision.
          </p>
          <p>
          Our website manages and processes the documentation provided by users in order to facilitate the ITIN application process. We ensure confidentiality and security of all personal and corporate data entrusted to us.
          </p>

          <h2 className="sub-terms">Recipients of the Data</h2>
          <p>
          Itin Support LLC takes data privacy seriously and implements appropriate measures to safeguard personal data. We use up-to-date technology and employ security measures, such as encryption, firewalls, and secure servers, to protect personal data collected through the website and during the ITIN application process. We do not share personal data with third parties who may offer similar services.
          </p>

          <h2 className="sub-terms">Length of Time Personal Data is Stored</h2>
          <p>
          All information collected and processed will be retained only for as long as necessary to fulfill the purpose for which it was collected and to comply with legal obligations. We store personal information securely and ensure it is treated as strictly private and confidential.
          </p>
          <h2 className="sub-terms">Rights of the Applicant</h2>
          <p>
          As a user of itin.support, you have the right to access, rectify, or delete your personal data. If you have any inquiries or wish to exercise your rights, please contact us at [email address]. We will respond to your queries within a reasonable timeframe.
          </p>
          <p>
          Itin Support LLC is committed to maintaining the privacy and security of your personal data. For any concerns, questions, or requests regarding this Privacy Policy, please contact us at support@itin.support.
          </p>
          <p>
          Itin Support LLC cooperates with users, authorities, and law enforcement agencies to ensure compliance with national and international laws. If you believe that any content on the website violates applicable laws, rights of third parties, or morality and public order, please notify us immediately.
          </p>

          <h2 className="sub-terms">Cookies Policy</h2>
            <p>
            Itin Support LLC uses cookies on the itin.support website to enhance user experience and improve the functionality of the site. Cookies are information files stored on the user's computer that help facilitate browsing and provide useful information about the user's behavior on the internet. Our cookies do not contain personally identifiable information and cannot read data from the user's hard drive or install malware.
            </p>
            <p>
            Cookies are not necessary for accessing the website, but disabling them may impact certain features and functionality. Cookies are used to:
            </p>
            <ul>
                <li>Improve website performance and user preferences.</li>
                <li>Enhance protection and security against cyber-attacks.</li>
                <li>Gather statistical data for service quality improvement.</li>
                <li>Optimize the displayed advertising based on user interests.</li>
            </ul>
            <p>
            To learn more about revoking consent, disabling cookies, or for any questions about our Cookies Policy, please contact us at [email address] with "Cookies Policy" as the subject.
            </p>
            <p>
            By using the itin.support website, you agree to be bound by the Terms and Conditions published on the site. Please review the Terms and Conditions to fully understand our services and obligations.
            </p>

          <h2 className="sub-terms">Legal Compliance</h2>
          <p>
          Itin Support LLC fully complies with the General Data Protection Regulation. For verification, you can contact the relevant data protection authority, such as the [Name of Data Protection Authority].
          </p>

        </div>
      </div>
      
      <button className="back-button" onClick={goBack}>
        Return
      </button>
    </div>
  );
};

export default PrivacyPolicy;
