import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Header from "../pages/components/Header";
import Footer from "../pages/components/Footer";
import Chip from '@mui/material/Chip';
import './Profile.css'; // Ensure this CSS file exists and contains styling for the profile
import licenseIcon from '../assets/license.png'; 
import Loader from '../pages/components/Loader'

const PublicProfile = () => {
  const { username } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        setUserProfile(userDoc.data());
      } else {
        console.log("No such document with the username:", username);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username, firestore]);

  if (!userProfile) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="user-profile">
        <div className="top-section">
          <div className="left-box">
            <img src={userProfile.profilePictureUrl || '/default-profile.png'} alt="Profile" className="profile-picture" />
            <div className="name-username-location">
              <h1>{`${userProfile.firstName || 'First'} ${userProfile.lastName || 'Lastname'}`}</h1>
              <p className="username">@{userProfile.username}</p>
              <p className="location">{`${userProfile.state || 'City'}, ${userProfile.country || 'Country'}`}</p>
            </div>
          </div>
          <div className="right-box">
            <p className="caa-license-label">CAA License</p>
            <a href={userProfile.caaLicenseUrl} target="_blank" rel="noopener noreferrer">
              <img src={licenseIcon} alt="CAA License" className="caa-license" />
            </a>
          </div>
        </div>
        <hr className="divider" />
        <div className="profile-details">
          <div className="detail-item">
            <strong>Bio:</strong> <Chip label={userProfile.bio || 'Your bio here'} />
          </div>
          <div className="detail-item">
            <strong>Email:</strong> <Chip label={userProfile.email || 'example@email.com'} />
          </div>
          <div className="detail-item">
            <strong>Service Areas:</strong>
            {userProfile.isOpenToAll ? (
              <Chip label="Global" />
            ) : (
              userProfile.selectedCountries && userProfile.selectedCountries.map((country, index) => (
                <Chip key={index} label={country} />
              ))
            )}
          </div>
          <div className="detail-item">
            <strong>Languages Spoken:</strong>
            {userProfile.languages && userProfile.languages.map((language, index) => (
              <Chip key={index} label={language} />
            ))}
          </div>
          {userProfile.businessName && (
            <div className="detail-item">
              <strong>Business Name:</strong> {userProfile.businessName}
            </div>
          )}
          {userProfile.website && (
            <div className="detail-item">
              <strong>Website:</strong> <Chip label={<a href={userProfile.website} target="_blank" rel="noopener noreferrer">{userProfile.website}</a>} />
            </div>
          )}
          <div className="detail-item">
            <strong>Contents Needed for Approval:</strong>
            {Array.isArray(userProfile.contentRequirements) ? (
              userProfile.contentRequirements.map((contentRequirement, index) => (
                <Chip key={index} label={contentRequirement} style={{ marginRight: "8px", marginBottom: "8px" }} />
              ))
            ) : (
              <Chip label="No requirements specified" style={{ marginRight: "8px", marginBottom: "8px" }} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PublicProfile;
