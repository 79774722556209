import React from 'react';
import { TextField, Switch, FormControlLabel, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const ForeignTaxID = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = React.useContext(FormContext);
  const [fieldError, setFieldError] = React.useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      foreignTaxID: value,
    }));
    if (fieldError) {
      setFieldError('');
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: checked,
      foreignTaxID: checked ? prevState.foreignTaxID || '' : '',
    }));
  };

  const nextPage = () => {
    if (formData.hasForeignTaxID && (formData.foreignTaxID === '' || formData.foreignTaxID == null)) {
      setFieldError(t('foreignTaxIDError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="switcher" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('taxID')}</span>
        <Tooltip title={t('infoForeignTaxID')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
        <div className="sub-sub-title">{t('hasForeignTaxID')}</div>
        <Box>
          <FormControlLabel
            control={
              <Switch 
                checked={formData.hasForeignTaxID} 
                onChange={handleSwitchChange} 
                name="hasForeignTaxID" 
              />
            }
            label={formData.hasForeignTaxID ? t('yes') : t('no')}
          />
        </Box>
      {formData.hasForeignTaxID && (
        <TextField
          name="foreignTaxID"
          value={formData.foreignTaxID || ''}
          onChange={handleInputChange}
          variant="standard"
          className="input-field"
          label={t('foreignTaxID')}
          required={formData.hasForeignTaxID}
          error={Boolean(fieldError) && formData.hasForeignTaxID}
          helperText={fieldError}
        />
      )}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default ForeignTaxID;
