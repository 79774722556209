import React from 'react';

const NotificationDropdown = ({ notifications }) => {
  return (
    <div className="notification-dropdown">
      {notifications.length > 0 ? (
        notifications.map(notification => (
          <div key={notification.id} className="notification-item">
            <h4>{notification.title}</h4>
            <p>{notification.message}</p>
          </div>
        ))
      ) : (
        <div className="notification-empty">
          No new alerts
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
