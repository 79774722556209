import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import OnboardingStepOne from './Onboarding/OnboardingStepOne';
import OnboardingStepTwo from './Onboarding/OnboardingStepTwo';
import './Onboarding/Onboarding.css';

const CAAOnboarding = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userId, setUserId] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    state: '',
    country: '',
    businessName: '',
    isOpenToAll: true,
    selectedCountries: [],
    contentRequirements: [],
    otherContent: '',
    website: '',
    profilePictureUrl: '',
    caaLicenseUrl: '',
  });
  const navigate = useNavigate();
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchOnboardingProgress(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchOnboardingProgress = async (userId) => {
    const userRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setCurrentStep(userData.onboardingStep || 1);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        state: '',
        country: '',
        businessName: '',
        isOpenToAll: true,
        selectedCountries: [],
        contentRequirements: [],
        otherContent: '',
        website: '',
        profilePictureUrl: '',
        caaLicenseUrl: '',
        ...userData // Overwrite with user data
      });
    }
  };

  const handleNext = async () => {
    if (!userId) return;

    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, formData);
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const completeOnboarding = async () => {
    if (!userId) {
      console.error("User ID is missing");
      return;
    }

    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { onboardingCompleted: true });
      console.log("Onboarding completed successfully");
      navigate('/CAA');
    } catch (error) {
      console.error("Error completing onboarding:", error);
    }
  };

  return (
    <div>
      {currentStep === 1 && <OnboardingStepOne formData={formData} setFormData={setFormData} onNext={handleNext} />}
      {currentStep === 2 && <OnboardingStepTwo formData={formData} setFormData={setFormData} onComplete={completeOnboarding} />}
    </div>
  );
};

export default CAAOnboarding;
