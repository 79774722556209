import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { TextField, Tooltip, Switch, Autocomplete, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as HelpOutIcon } from '../../assets/icons/help.svg';
import logo from '../../assets/textlogo.svg';

const TealSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#40C4FF',
    '&:hover': {
      backgroundColor: 'rgba(64, 196, 255, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#40C4FF',
  },
}));

export default function OnboardingStepTwo({ formData, setFormData, onComplete }) {
  const [selectedContentRequirements, setSelectedContentRequirements] = useState(formData.contentRequirements || []);
  const [selectedLanguages, setSelectedLanguages] = useState(formData.languages || []);
  const [countryOptions, setCountryOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const auth = getAuth();
  const firestore = getFirestore();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;

  const languageOptions = ['English', 'Spanish', 'French', 'German', 'Italian', 'Hindi'];
  const contentRequirementOptions = ['W7', 'eSignature', 'Form 1040 (Tax Forms)', 'Passport Scan'];

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const options = data.map((country) => ({ key: country.cca2, label: country.name.common }));
        setCountryOptions(options);
      } catch (error) {
        console.error('Error fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, []);

  const handleContentRequirementsChange = (event, newValue) => {
    setSelectedContentRequirements(newValue || []);
    setFormData((prevFormData) => ({
      ...prevFormData,
      contentRequirements: newValue || [],
    }));
  };

  const handleLanguagesChange = (event, newValue) => {
    setSelectedLanguages(newValue || []);
    setFormData((prevFormData) => ({
      ...prevFormData,
      languages: newValue || [],
    }));
  };

  const handleCountrySelectionChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedCountries: newValue.map((item) => item.key),
    }));
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleProfilePictureChange = (event) => {
    handleFileUpload(event.target.files[0], 'profilePictureUrl');
  };

  const handleCaaLicenseChange = (event) => {
    handleFileUpload(event.target.files[0], 'caaLicenseUrl');
  };

  const handleFileUpload = async (file, key) => {
    if (!file || !userId) return;
    const storageRef = ref(getStorage(), `uploads/${userId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => {
        console.error('Upload error:', error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setFormData((prevFormData) => ({
            ...prevFormData,
            [key]: downloadURL,
          }));
          const userRef = doc(firestore, 'users', userId);
          await updateDoc(userRef, {
            [key]: downloadURL,
          });
        } catch (error) {
          console.error('Error getting download URL or updating Firestore:', error);
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      console.error("Missing user ID");
      setErrors((prevErrors) => ({
        ...prevErrors,
        userId: "User ID is missing. Please try logging out and logging in again.",
      }));
      return;
    }

    const combinedFormData = {
      ...formData,
      contentRequirements: selectedContentRequirements,
      languages: selectedLanguages,
      onboardingCompleted: true, // Ensure onboarding is marked as completed
    };

    try {
      console.log("Updating user document with:", combinedFormData);
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, combinedFormData);
      console.log("User document updated successfully");
      onComplete(); // Navigate to the CAA hub
    } catch (error) {
      console.error("Error during onboarding completion:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        submit: error.code === 'permission-denied'
          ? "You do not have permission to complete this action. Please check your user role or contact support."
          : `An unexpected error occurred: ${error.message}. Please try again.`,
      }));
    }
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const isAnyRequirementChecked = formData.contentRequirements.length > 0;
    if (!isAnyRequirementChecked) {
      newErrors.contentRequirements = "At least one content requirement must be checked.";
    }

    if (!formData.profilePictureUrl) {
      newErrors.profilePictureUrl = "Profile picture is required.";
    }
    if (!formData.caaLicenseUrl) {
      newErrors.caaLicenseUrl = "CAA License is required.";
    }
    if (!formData.isOpenToAll && formData.selectedCountries.length === 0) {
      newErrors.selectedCountries = "Please select at least one country.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="onboarding-step-two">
      <img src={logo} alt="Logo" className="onboarding-logo" />
      <div className="onboarding-card">
        <form onSubmit={handleFormSubmission}>
          <div className="form-field">
            <Tooltip title="Upload a professional profile picture. This image will help introduce you to your clients.">
              <label htmlFor="profile-picture-upload">Profile Picture <HelpOutIcon /> :</label>
            </Tooltip>
            <input
              className='side-space'
              type="file"
              id="profile-picture-upload"
              onChange={handleProfilePictureChange}
              accept="image/png, image/jpeg, image/heic"
            />
            {errors.profilePictureUrl && <div className="error-message">{errors.profilePictureUrl}</div>}
          </div>

          <div className="form-field">
            <Tooltip title="Provide documentation and proof to best verify your CAA license and receive approval.">
              <label htmlFor="caa-license-upload">CAA License <HelpOutIcon /> :</label>
            </Tooltip>
            <input
              className='side-space'
              type="file"
              id="caa-license-upload"
              onChange={handleCaaLicenseChange}
              accept="application/pdf, image/png, image/jpeg"
            />
            {errors.caaLicenseUrl && <div className="error-message">{errors.caaLicenseUrl}</div>}
          </div>

          <div className="form-field">
            <Tooltip title="Check the documents needed to ensure your ITIN's application approval">
              <label htmlFor="itin-needed-documents">Content required for ITIN application approval <HelpOutIcon /> :</label>
            </Tooltip>
            <Autocomplete
              multiple
              id="content-requirements-select"
              options={contentRequirementOptions}
              getOptionLabel={(option) => option}
              value={selectedContentRequirements}
              onChange={handleContentRequirementsChange}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={option} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  variant="outlined" 
                  label="Add requirements" 
                  placeholder="Type or select" 
                  sx={{ marginTop: 2 }}
                />
              )}
            />
          </div>

          <div className="form-field">
            <label htmlFor="languages">Languages Spoken:</label>
            <Autocomplete
              multiple
              id="languages-select"
              options={languageOptions}
              getOptionLabel={(option) => option}
              value={selectedLanguages}
              onChange={handleLanguagesChange}
              filterSelectedOptions
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={option} label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select or add languages"
                  placeholder="Type or select"
                  sx={{ marginTop: 2 }}
                />
              )}
            />
          </div>

          <div className="form-field">
            <Tooltip title="Please switch on if you are open to receive applications across the world.">
              <label htmlFor="itin-needed-documents">I'm open to applications from clients outside the US <HelpOutIcon /> :</label>
            </Tooltip>
            <Tooltip title="Indicate if you're open to working with clients outside the US. This increases your potential client base.">
              <TealSwitch
                checked={formData.isOpenToAll ?? true}
                onChange={handleInputChange}
                name="isOpenToAll"
                sx={{ marginTop: 1 }}
              />
            </Tooltip>
          </div>

          {!formData.isOpenToAll && (
            <div className="form-field">
              <p>Please select the countries you'd like to receive applications from:</p>
              <Autocomplete
                multiple
                id="country-select"
                options={countryOptions}
                getOptionLabel={(option) => option.label}
                value={formData.selectedCountries.map(countryKey => countryOptions.find(option => option.key === countryKey) || { key: countryKey, label: countryKey })}
                onChange={handleCountrySelectionChange}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    className='input-field'
                    variant="standard"
                    label="Select countries"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ marginTop: 2, width: '100%' }}
                  />}
              />
              {errors.selectedCountries && <div className="error-message">{errors.selectedCountries}</div>}
            </div>
          )}

          <div className="form-field">
            <label htmlFor="business-name">Business Name (Optional)</label>
            <input
              className='side-space'
              type="text"
              id="business-name"
              name="businessName"
              value={formData.businessName}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-field">
            <label htmlFor="business-website">Business Website (if applicable)</label>
            <input
              className='side-space'
              type="url"
              id="business-website"
              name="website"
              value={formData.website}
              onChange={handleInputChange}
            />
          </div>

          <div className="submit-button-container">
            <button className="back-button">Back</button>
            <button className="continue-button" type="submit">Complete Onboarding</button>
          </div>
        </form>
        {errors.submit && <div className="error-message">{errors.submit}</div>}
      </div>
    </div>
  );
}
