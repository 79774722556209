import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, query, onSnapshot, addDoc, orderBy, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import SendIcon from '../assets/icons/send.svg';
import AttachmentIcon from '../assets/icons/folder-add.svg';
import './AdminMessages.css';

const AdminMessages = () => {
    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const auth = getAuth();
    const firestore = getFirestore();

    useEffect(() => {
        const q = query(collection(firestore, 'Submissions'), where('PaymentConfirmation', '==', true));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedConversations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setConversations(fetchedConversations);
            if (fetchedConversations.length > 0) {
                setCurrentConversation(fetchedConversations[0]);
            }
        });
        return () => unsubscribe();
    }, [firestore]);

    useEffect(() => {
        if (currentConversation && currentConversation.id) {
            const messagesRef = collection(firestore, 'Messages');
            const q = query(messagesRef, where('submissionId', '==', currentConversation.id), orderBy('timestamp'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const fetchedMessages = querySnapshot.docs.map(doc => doc.data());
                setMessages(fetchedMessages);
            });
            return () => unsubscribe();
        }
    }, [firestore, currentConversation]);

    useEffect(() => {
        const messagesContainer = document.querySelector('.messages');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [messages]);

    const handleFileSelect = (event) => {
        const fileToUpload = event.target.files[0];
        if (fileToUpload) {
            setFile(fileToUpload);
        }
    };

    const uploadFile = async (file) => {
        if (!file) return;
        setUploading(true);

        const storageRef = ref(getStorage(), `chat_uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress updates here if needed
            },
            (error) => {
                console.error('Upload error:', error);
                setUploading(false);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                await sendMessage(downloadURL, 'file');
                setUploading(false);
                setFile(null);
            }
        );
    };

    const sendMessage = async (content, type = 'text') => {
        if (!content.trim()) return;
        await addDoc(collection(firestore, 'Messages'), {
            submissionId: currentConversation.id,
            message: type === 'text' ? content : '',
            file: type === 'file' ? content : '',
            senderId: auth.currentUser.uid,
            timestamp: new Date(),
            type: type,
        });
        setNewMessage('');
    };

    const handleSendMessage = () => {
        if (file) {
            uploadFile(file);
        } else {
            sendMessage(newMessage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleAttachmentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            setFile(files[0]);
        }
    };

    return (
        <div className="admin-messages" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className="recent-contacts">
                {conversations.map((conversation) => (
                    <div
                        key={conversation.id}
                        className={`contact-item ${currentConversation && currentConversation.id === conversation.id ? 'active' : ''}`}
                        onClick={() => setCurrentConversation(conversation)}
                    >
                        {`${conversation.firstName} ${conversation.lastName}`}
                    </div>
                ))}
            </div>
            <div className="message-history">
                {currentConversation && (
                    <>
                        <h2 className="message-title">{`${currentConversation.firstName} ${currentConversation.lastName}`}</h2>
                        <div className="messages">
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`message ${
                                        msg.senderId === auth.currentUser.uid
                                            ? 'admin-sent'
                                            : msg.senderId === 'customer'
                                            ? 'received'
                                            : 'caa-sent'
                                    }`}
                                >
                                    {msg.message}
                                    {msg.type === 'file' && (
                                        <a href={msg.file} target="_blank" rel="noopener noreferrer">
                                            Download File
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="message-box">
                            <textarea
                                className="textarea"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Type a message..."
                            />
                            {file && <span>{file.name}</span>}
                            <input
                                type="file"
                                onChange={handleFileSelect}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                            <button className="attachment-button" onClick={handleAttachmentClick}>
                                <img src={AttachmentIcon} alt="Attach file" />
                            </button>
                            <button className="send-button" onClick={handleSendMessage}>
                                <img src={SendIcon} alt="Send" />
                            </button>
                        </div>
                        {uploading && <div>Uploading...</div>}
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminMessages;
