import React, { useContext, useState, useEffect } from 'react';
import MultiStepForm from './components/MultiStepForm';
import { FormContext } from '../FormContext';
import Header from "./components/Header";
import Footer from "./components/Footer"; 

const ITIN = () => {
  const { formData } = useContext(FormContext);
  const [progress, setProgress] = useState(0);  // correct way to destructure useState

  useEffect(() => {
    const stepsCount = Object.keys(formData).length;
    const filledSteps = Object.values(formData).filter(v => v !== "").length;
    setProgress((filledSteps / stepsCount) * 100);
  }, [formData]);

  return (
    <div>
    <Header/>
    <div className="homepage">
      <MultiStepForm />
    </div>
    <Footer/>
    </div>
  );
};

export default ITIN;
