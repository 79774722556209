import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import {
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import './CAASettings.css';

const ReauthModal = ({ isOpen, onClose, onSubmit, onPasswordChange, currentPassword }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>Verify it's you</h3>
        <p>To ensure it's really you, please enter your current password.</p>
        <input
          type="password"
          value={currentPassword}
          onChange={onPasswordChange}
          placeholder="Current Password"
        />
        <div className="modal-actions">
          <button onClick={onSubmit}>Verify</button>
          <button onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
};

const SettingsPage = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReauthOpen, setIsReauthOpen] = useState(false);
  const [userProvider, setUserProvider] = useState('');
  const [userPlan, setUserPlan] = useState(null); 

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          setUserProvider(user.providerData[0]?.providerId || '');
          
          const userRef = doc(firestore, 'customers', user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUserPlan(userDoc.data().planId);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setErrorMessage('Failed to fetch user data. Please try again.');
        }
      } else {
        console.log('No user is signed in.');
        // Redirect to login or show appropriate message
      }
    };

    fetchUserData();
  }, []);

  const reauthenticate = async () => {
    try {
      if (userProvider === 'google.com') {
        // Use Google for reauthentication
        const googleProvider = new GoogleAuthProvider();
        await signInWithPopup(auth, googleProvider);
      } else {
        // Use Email/Password for reauthentication
        const credential = EmailAuthProvider.credential(auth.currentUser.email, currentPassword);
        await reauthenticateWithCredential(auth.currentUser, credential);
      }
    } catch (error) {
      console.error('Reauthentication error:', error);
      setErrorMessage(error.message);
    }
  };

  const handleChangeEmail = async () => {
    if (!email) {
      setErrorMessage("Please enter a new email.");
      return;
    }
    try {
      await reauthenticate();
      const user = auth.currentUser;
      if (user) {
        const actionCodeSettings = {
          url: window.location.href,
        };
        await user.verifyBeforeUpdateEmail(email, actionCodeSettings);
        const userRef = doc(firestore, 'customers', user.uid);
        await updateDoc(userRef, {
          'tempEmail': email,
        });
        setEmail('');
        setErrorMessage('');
        alert('Verification email sent. Please check your email to confirm the change.');
      } else {
        throw new Error('No user is signed in.');
      }
    } catch (error) {
      console.error('Error changing email:', error);
      setErrorMessage(error.message);
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword) {
      setErrorMessage("Please enter a new password.");
      return;
    }
    try {
      await reauthenticate();
      await updatePassword(auth.currentUser, newPassword);
      setNewPassword('');
      setErrorMessage('');
      alert('Password updated successfully!');
      setIsReauthOpen(false);
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage(error.message);
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    try {
      await reauthenticate();
      await deleteDoc(doc(firestore, 'customers', auth.currentUser.uid));
      await deleteUser(auth.currentUser);
      alert('Account deleted successfully.');
    } catch (error) {
      console.error('Error deleting account:', error);
      setErrorMessage(error.message);
    }
  };

  const handlePasswordChangeClick = () => {
    setIsReauthOpen(true);
  };

  return (
    <div className="settings-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="settings-form">
        {userProvider === 'password' && (
          <>
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="New Email"
              />
              <button className="back-button" onClick={handleChangeEmail}>Change Email</button>
            </div>
            <div className="form-group">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <button className="back-button" onClick={handlePasswordChangeClick}>Change Password</button>
            </div>
          </>
        )}
        <div className="form-group">
          <button onClick={handleDeleteAccount} className="back-button">Delete Account</button>
        </div>
      </div>
      <ReauthModal
        isOpen={isReauthOpen}
        onClose={() => setIsReauthOpen(false)}
        onSubmit={handleChangePassword}
        onPasswordChange={(e) => setCurrentPassword(e.target.value)}
        currentPassword={currentPassword}
      />
    </div>
  );
};

export default SettingsPage;
