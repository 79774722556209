import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Chip, Avatar } from '@mui/material';
import './component.css';
import UserProfileModal from './UserProfileModal';

const Verify = () => {
  const [usersToVerify, setUsersToVerify] = useState([]);
  const [statusFilter, setStatusFilter] = useState('pending');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUsersToVerify = async () => {
      let queryToFetch = collection(firestore, 'users');

      if (statusFilter === 'pending') {
        queryToFetch = query(
          queryToFetch,
          where('onboardingCompleted', '==', true),
          where('caaStatus', '==', null)
        );
      } else if (statusFilter === 'approved') {
        queryToFetch = query(
          queryToFetch,
          where('onboardingCompleted', '==', true),
          where('caaStatus', '==', true)
        );
      }

      const querySnapshot = await getDocs(queryToFetch);
      const users = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUsersToVerify(users);
    };

    fetchUsersToVerify();
  }, [firestore, statusFilter]);

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    setIsDropdownOpen(false);
  };

  const handleOpenModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleApproveUser = async (userId) => {
    await updateDoc(doc(firestore, 'users', userId), { caaStatus: true });
    setUsersToVerify(usersToVerify.filter(user => user.id !== userId));
    handleCloseModal();
  };

  const handleDeclineUser = async (userId) => {
    try {
      await updateDoc(doc(firestore, 'users', userId), { caaStatus: false });
      setUsersToVerify(usersToVerify.filter(user => user.id !== userId));
      handleCloseModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div className="verify-container">
      <header className="verify-header">
        <h1 className="verify-title">Users to Verify</h1>
        <div className="custom-dropdown">
          <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {statusFilter === 'pending' ? 'Pending' : 'Approved'}
            <span className="dropdown-arrow"></span>
          </button>
          {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li onClick={() => handleStatusFilterChange('pending')}>Pending</li>
              <li onClick={() => handleStatusFilterChange('approved')}>Approved</li>
            </ul>
          )}
        </div>
      </header>
      <div className="verify-grid">
        {usersToVerify.map(user => (
          <div key={user.id} className="verify-grid-item">
            <Chip
              avatar={<Avatar alt={user.firstName || 'User'} src={user.profilePictureUrl || '/default-profile.png'} />}
              label={`${user.firstName} ${user.lastName}`.trim() || 'Unknown User'}
              clickable
              color="primary"
              variant="outlined"
              onClick={() => handleOpenModal(user)}
            />
          </div>
        ))}
      </div>
      {selectedUser && (
        <UserProfileModal 
          open={isModalOpen} 
          onClose={handleCloseModal} 
          userProfile={selectedUser}
          onApprove={() => handleApproveUser(selectedUser.id)}
          onDecline={() => handleDeclineUser(selectedUser.id)}
        />
      )}
    </div>
  );
};

export default Verify;
