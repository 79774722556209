import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, TextField, Tooltip, Box, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const CountriesOfCitizenship = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = React.useContext(FormContext);
  const [countryOptions, setCountryOptions] = useState([]);
  const [fieldError, setFieldError] = React.useState('');
  const [hasMultipleCitizenship, setHasMultipleCitizenship] = useState(false);

  formData.countriesOfCitizenship = formData.countriesOfCitizenship || [];

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const countries = await response.json();
        const options = countries.map((country) => ({
          key: country.cca2,
          value: country.cca2,
          label: country.name.common,
        }));
        setCountryOptions(options);
      } catch (error) {
        console.log('Error fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, []);

  const handleCountryChange = (event, newValue) => {
    const newCountries = newValue.map((item) => item.label);
    setFormData((prevState) => ({
      ...prevState,
      countriesOfCitizenship: newCountries,
    }));
  };

  const nextPage = () => {
    if (hasMultipleCitizenship && (!formData.countriesOfCitizenship || formData.countriesOfCitizenship.length === 0)) {
      setFieldError(t('countriesOfCitizenshipError'));
      return;
    }
    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('basicInfo')}</span>
        <Tooltip title={t('infoDisclaimer')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div className="sub-sub-title">{t('infoCountriesOfCitizenship')}</div>
      <RadioGroup
        aria-label="multipleCitizenship"
        name="multipleCitizenship"
        value={String(hasMultipleCitizenship)}
        onChange={(e) => setHasMultipleCitizenship(e.target.value === 'true')}
      >
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={t('singleCitizenship', { country: formData.countryOfBirth })}
        />
        <FormControlLabel
          value="true"
          control={<Radio />}
          label={t('multipleCitizenship', { country: formData.countryOfBirth })}
        />
      </RadioGroup>

      {hasMultipleCitizenship && (
        <>
          <div className="instruction-title">{t('selectOtherCountries')}</div>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Autocomplete
              multiple
              id="countriesOfCitizenship"
              options={countryOptions}
              getOptionLabel={(option) => option.label}
              value={(countryOptions || []).filter(option => formData.countriesOfCitizenship?.includes(option.label))}
              onChange={handleCountryChange}
              renderInput={(params) => (
                <TextField {...params} variant="standard" style={{ width: 300 }} />
              )}
            />
          </Box>
        </>
      )}
      {fieldError && <div className="error-message">{fieldError}</div>}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default CountriesOfCitizenship;
