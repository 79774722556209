import React from 'react';
import { FormHelperText, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const Gender = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = React.useContext(FormContext);
  const [gender, setGender] = React.useState(formData.gender || '');
  const [fieldError, setFieldError] = React.useState('');

  const handleGenderChange = (event) => {
    const value = event.target.value;
    setGender(value);
    setFormData(prevFormData => ({
      ...prevFormData,
      gender: value,
    }));
    if (fieldError) {
      setFieldError('');
    }
  };

  const nextPage = () => {
    if (!formData.gender) {
      setFieldError(t('genderError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('basicInfo')}</span>
        <Tooltip title={t('infoBasicInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <span>{t('gender')}</span>
      <div className={`radio-group ${fieldError ? 'error' : ''}`}>
        <label className="radio-option">
          <input
            type="radio"
            name="gender"
            value="male"
            checked={gender === 'male'}
            onChange={handleGenderChange}
          />
          {t('male')}
        </label>
        <label className="radio-option">
          <input
            type="radio"
            name="gender"
            value="female"
            checked={gender === 'female'}
            onChange={handleGenderChange}
          />
          {t('female')}
        </label>
      </div>
      <FormHelperText error={Boolean(fieldError)}>{fieldError}</FormHelperText>
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default Gender;