import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, query, where, onSnapshot, addDoc, orderBy, getDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './Messages.css'; // Ensure your CSS file path is correct
import SendIcon from '../../assets/icons/send.svg'; // Update with correct path
import AttachmentIcon from '../../assets/icons/folder-add2.svg'; // Update with correct path

const CustomerMessages = ({ submissionId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const messagesEndRef = useRef(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const firestore = getFirestore();

  useEffect(() => {
    if (!submissionId) return;
    const messagesRef = collection(firestore, 'Messages');
    const q = query(messagesRef, where('submissionId', '==', submissionId), orderBy('timestamp'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() // Convert timestamp to Date object
      }));
      setMessages(fetchedMessages);
    });

    return unsubscribe;
  }, [firestore, submissionId]);

  useEffect(() => {
    const fetchCaaAgentProfile = async () => {
      if (!submissionId) return;
      const submissionDoc = await getDoc(doc(firestore, 'Submissions', submissionId));
      if (submissionDoc.exists()) {
        const submissionData = submissionDoc.data();
        const caaAgentId = submissionData.caaAgent;
        if (caaAgentId) {
          const caaAgentDoc = await getDoc(doc(firestore, 'users', caaAgentId));
          if (caaAgentDoc.exists()) {
            const caaAgentData = caaAgentDoc.data();
            const notificationMessage = `CAA Agent ${caaAgentData.firstName} ${caaAgentData.lastName} was assigned your application`;
            await addDoc(collection(firestore, 'Messages'), {
              submissionId: submissionId,
              message: notificationMessage,
              senderId: 'system',
              timestamp: new Date(),
              type: 'notification'
            });
          }
        }
      }
    };
    fetchCaaAgentProfile();
  }, [firestore, submissionId]);

  useEffect(() => {
    // Scroll to the bottom of the messages area
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = async () => {
    if (file) {
      await uploadFile(file);
    } else if (newMessage.trim() !== '') {
      await sendMessage(newMessage, 'text');
    }
  };

  const uploadFile = async (file) => {
    setUploading(true);
    const storageRef = ref(getStorage(), `chat_uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', (snapshot) => {}, (error) => {
      console.error('Upload error:', error);
      setUploading(false);
    }, async () => {
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      await sendMessage(downloadURL, 'file');
    });
  };

  const sendMessage = async (content, type) => {
    await addDoc(collection(firestore, 'Messages'), {
      submissionId: submissionId,
      message: type === 'text' ? content : '',
      file: type === 'file' ? content : null,
      senderId: 'customer', // Adjust if you have a way to identify the sender
      timestamp: new Date(),
      type: type,
    });
    setNewMessage('');
    setFile(null);
    setUploading(false);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      setFile(files[0]);
    }
  };

  return (
    <div className="chat" onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className="messages-text">
        <div className="messages-content">
        {messages.map((msg) => (
          <div key={msg.id} className={`message ${
            msg.senderId === 'customer'
              ? 'message-personal'
              : msg.senderId === 'admin'
              ? 'admin-sent'
              : msg.senderId === 'system'
              ? 'notification'
              : 'caa-sent'
          }`}>
            {msg.type === 'file' ? (
              <a href={msg.file} target="_blank" rel="noopener noreferrer">View Attachment</a>
            ) : (
              <div className="message-text">{msg.message}</div>
            )}
            <div className="timestamp">{msg.timestamp ? new Date(msg.timestamp).toLocaleTimeString() : ''}</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
        </div>
      </div>
      <div className="message-container">
        <textarea className="message-input" placeholder="Type message..." value={newMessage} onKeyDown={handleKeyDown} onChange={(e) => setNewMessage(e.target.value)}></textarea>
        {file && <div className="file-preview">{file.name}</div>}
        <img src={AttachmentIcon} alt="Attach" className="attach-icon" onClick={() => fileInputRef.current.click()} />
        <input type="file" onChange={handleFileSelect} ref={fileInputRef} style={{ display: 'none' }} />
        <button type="submit" className="message-submit" onClick={handleSendMessage}>
          <img src={SendIcon} alt="Send" />
        </button>
      </div>
      {uploading && <div>Uploading...</div>}
    </div>
  );
};

export default CustomerMessages;
