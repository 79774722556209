import React from 'react';
import { useLocation, Outlet } from 'react-router-dom'; 
import SideNav from '../pages/components/AdminHeader';
import DashboardHeader from '../pages/components/DashboardHeader';
import useFetchNotifications from '../hooks/useFetchNotifications'; // Your custom hook to fetch notifications
import './Dashboard.css';
import { getAuth } from 'firebase/auth';

const Dashboard = () => {
    const location = useLocation();
    const notifications = useFetchNotifications();
    const auth = getAuth();
    const user = auth.currentUser;
    let title;

    switch (location.pathname) {
        case '/admin/':
            title = 'Dashboard';
            break;
        case '/admin/submissions':
            title = 'Submissions';
            break;
        case '/admin/messages':
            title = 'Messages';
            break;
        case '/admin/settings':
            title = 'Settings';
            break;
        default:
            title = 'Dashboard';
    }

    return (
        <div className="dashboard">
            <SideNav />
            <div className="dashboard-content">
                <DashboardHeader title={title} user={user} notifications={notifications} />
                <Outlet />
            </div>
        </div>
    );
};

export default Dashboard;
