import React, { useState } from 'react';
import { TextField, Switch, FormControlLabel, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const Visa = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = React.useContext(FormContext);
  const [fieldError, setFieldError] = useState('');

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (fieldError) {
      setFieldError('');
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
  
    setFormData(prevState => ({
      ...prevState,
      [name]: checked,
      visaNumber: checked ? prevState.visaNumber || '' : '',
      visaExpirationDate: checked ? prevState.visaExpirationDate || '' : '',
    }));
  };

  const nextPage = () => {
    if (formData.hasUSVisa && (formData.visaNumber === '' || !formData.visaExpirationDate)) {
      setFieldError(t('usVisaError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="switcher" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('taxID')}</span>
        <Tooltip title={t('infoHasUSVisa')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div className="sub-sub-title">{t('hasUSVisa')}</div>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <FormControlLabel
          control={
            <Switch 
              checked={formData.hasUSVisa} 
              onChange={handleSwitchChange} 
              name="hasUSVisa"
            />
          }
          label={formData.hasUSVisa ? t('yes') : t('no')}
        />
      </Box>
      {formData.hasUSVisa && (
        <div>
          <TextField
            name="visaNumber"
            value={formData.visaNumber || ''}
            onChange={handleChange}
            variant="standard"
            className="input-field"
            label={t('visaNumber')}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            name="visaExpirationDate"
            value={formData.visaExpirationDate || ''}
            onChange={handleChange}
            variant="standard"
            className="input-field"
            type="date"
            label={t('visaExpirationDate')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}
      {fieldError && <div className="error-message">{fieldError}</div>}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default Visa;
