// src/AppRoutes.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuthentication } from "./hooks/useAuthentication";
import AdminLogin from "./Admin/AdminLogin";
import HomePage from "./pages/HomePage";
import CAAHub from "./CAA/CAAHub";
import CAASettings from "./CAA/CAASettings";
import CAAMessages from "./CAA/CAAMessages";
import CAASubmissions from "./CAA/CAASubmissions";
import CAAHome from "./CAA/CAAHome";
import Cancel from "./pages/CancelPage";
import Success from "./pages/Success";
import Dashboard from "./Admin/Dashboard";
import AdminHome from "./Admin/AdminHome";
import AdminSettings from "./Admin/AdminSettings";
import Submissions from "./Admin/Submissions";
import AdminMessages from "./Admin/AdminMessages";
import SubmissionDetails from "./Admin/SubmissionDetail";
import TermsAndConditions from "./pages/legal/TermsandConditions";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import ITIN from "./pages/ITIN";
import CAAOnboarding from "./CAA/CAAOnboarding";
import PublicProfile from "./CAA/Profile";
import UserProfile from "./CAA/UserProfile";
import NotFoundPage from "./pages/NotFound";
import ProtectedRoute from './ProtectedRoute';
import Loader from './pages/components/Loader';
import Premium from './CAA/Premium'; 
import PublicITIN from './pages/PublicITIN'; 
import PublicITINForm from './pages/PublicITINForm'; // Import the new PublicITINForm component

function AppRoutes() {
  const { user, role, onboardingCompleted, loading } = useAuthentication();

  useEffect(() => {
    console.log("Authentication state:", { user, role, onboardingCompleted, loading });
  }, [user, role, onboardingCompleted, loading]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/itin" element={<ITIN />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-protection" element={<PrivacyPolicy />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/login" element={<AdminLogin />} />

        {/* Admin Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/admin/*" element={role === 'super_admin' ? <Dashboard /> : <Navigate to="/login" replace />}>
            <Route index element={<AdminHome />} />
            <Route path="settings" element={<AdminSettings />} />
            <Route path="messages" element={<AdminMessages />} />
            <Route path="submissions" element={<Submissions />} />
            <Route path="submission/:id" element={<SubmissionDetails />} />
          </Route>
        </Route>

        {/* CAA Routes */}
        <Route element={<ProtectedRoute />}>
          <Route 
            path="/CAA/*" 
            element={
              role === 'CAA' 
                ? (onboardingCompleted ? <CAAHub /> : <Navigate to="/CAA/onboarding" replace />)
                : <Navigate to="/login" replace />
            }
          >
            <Route index element={<CAAHome />} />
            <Route path="settings" element={<CAASettings />} />
            <Route path="messages" element={<CAAMessages />} />
            <Route path="submissions" element={<CAASubmissions />} />
            <Route path="submission/:id" element={<SubmissionDetails />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="premium" element={<Premium />} />
          </Route>
        </Route>

        {/* CAA Onboarding Route - outside of protected routes */}
        <Route 
          path="/CAA/onboarding" 
          element={
            user && role === 'CAA' && !onboardingCompleted 
              ? <CAAOnboarding /> 
              : <Navigate to="/CAA" replace />
          } 
        />

        {/* Public ITIN Form Route */}
        <Route 
          path="/public/:userId" 
          element={<PublicITIN />}
        />
        <Route 
          path="/public/:userId/form" 
          element={<PublicITINForm />} // Add this new route
        />

        {/* Public Profile Route */}
        <Route path="/profile/:username" element={<PublicProfile />} />

        {/* Catch-all for 404 Not Found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
