import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './SubmissionDetail.css';
import { getFirestore, doc, getDoc, updateDoc, addDoc, deleteField, collection } from 'firebase/firestore';
import { firebaseConfig } from '../firebase';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { PDFDocument } from 'pdf-lib';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import printerIcon from '../assets/icons/print.svg';
import { Chip } from '@mui/material'; // Import Chip component

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`;

const SubmissionDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submission, setSubmission] = useState({});
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const [showDeclineConfirmationDialog, setShowDeclineConfirmationDialog] = useState(false);
  const [showTrackingDialog, setShowTrackingDialog] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  const auth = getAuth();
  const user = auth.currentUser;
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const options = useMemo(() => ({
    workerSrc: `${process.env.PUBLIC_URL}/pdf.worker.mjs`,
  }), []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${month}${day}${year}`;
  };

  useEffect(() => {
    const fetchSubmissionAndGeneratePDF = async () => {
      try {
        // Check if submission is in Submissions or in user submissions
        let submissionDocRef = doc(firestore, 'Submissions', id);
        let submissionSnapshot = await getDoc(submissionDocRef);

        if (!submissionSnapshot.exists()) {
          // If not found, check user submissions
          submissionDocRef = doc(firestore, `users/${user.uid}/submissions`, id);
          submissionSnapshot = await getDoc(submissionDocRef);
        }

        if (submissionSnapshot.exists()) {
          const data = submissionSnapshot.data();
          setSubmission(data);

          const formUrl = '/w7.pdf';
          const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer());
          const pdfDoc = await PDFDocument.load(formPdfBytes);
          const form = pdfDoc.getForm();

          const fields = form.getFields();
          fields.forEach(field => {
            console.log(`Field name: ${field.getName()}, Type: ${field.constructor.name}`);
          });

          const fieldMapping = {
            firstName: 'topmostSubform[0].Page1[0].f1_7[0]',
            lastName: 'topmostSubform[0].Page1[0].f1_9[0]',
            residentStreet: 'topmostSubform[0].Page1[0].f1_13[0]',
            residentCity: 'topmostSubform[0].Page1[0].f1_14[0]',
            residentState: 'topmostSubform[0].Page1[0].f1_14[0]',
            residentZipCode: 'topmostSubform[0].Page1[0].f1_14[0]',
            foreignStreet: 'topmostSubform[0].Page1[0].f1_15[0]',
            foreignCity: 'topmostSubform[0].Page1[0].f1_16[0]',
            foreignCountry: 'topmostSubform[0].Page1[0].f1_16[0]',
            foreignPostalCode: 'topmostSubform[0].Page1[0].f1_16[0]',
            dateOfBirth: 'topmostSubform[0].Page1[0].f1_17[0]',
            countryOfBirth: 'topmostSubform[0].Page1[0].f1_18[0]',
            countriesOfCitizenship: 'topmostSubform[0].Page1[0].f1_20[0]',
            phoneNumber: 'topmostSubform[0].Page1[0].f1_52[0]',
            otherReason: 'topmostSubform[0].Page1[0].f1_4[0]',
            entryDate: 'topmostSubform[0].Page1[0].f1_27[0]',
            expiryDate: 'topmostSubform[0].Page1[0].f1_26[0]',
            passportNumber: 'topmostSubform[0].Page1[0].f1_25[0]',
            passportIssuer: 'topmostSubform[0].Page1[0].f1_24[0]',
            foreignTaxID: 'topmostSubform[0].Page1[0].f1_21[0]',
            visaDetails: 'topmostSubform[0].Page1[0].f1_22[0]',
            residentOrSpouseInfo: 'topmostSubform[0].Page1[0].f1_3[0]',
            relationship: 'topmostSubform[0].Page1[0].f1_1[0]',
            itin: [
              'topmostSubform[0].Page1[0].f1_28[0]',
              'topmostSubform[0].Page1[0].f1_29[0]',
              'topmostSubform[0].Page1[0].f1_30[0]',
              'topmostSubform[0].Page1[0].f1_31[0]',
              'topmostSubform[0].Page1[0].f1_32[0]',
              'topmostSubform[0].Page1[0].f1_33[0]',
              'topmostSubform[0].Page1[0].f1_34[0]',
              'topmostSubform[0].Page1[0].f1_35[0]',
              'topmostSubform[0].Page1[0].f1_36[0]'
            ],
            irsn: [
              'topmostSubform[0].Page1[0].f1_37[0]',
              'topmostSubform[0].Page1[0].f1_38[0]',
              'topmostSubform[0].Page1[0].f1_39[0]',
              'topmostSubform[0].Page1[0].f1_40[0]',
              'topmostSubform[0].Page1[0].f1_41[0]',
              'topmostSubform[0].Page1[0].f1_42[0]',
              'topmostSubform[0].Page1[0].f1_43[0]',
              'topmostSubform[0].Page1[0].f1_44[0]',
              'topmostSubform[0].Page1[0].f1_45[0]'
            ],
          };

          const checkboxMapping = {
            applicationType: {
              new: 'topmostSubform[0].Page1[0].ApplicationType[0].c1_1[0]',
              renew: 'topmostSubform[0].Page1[0].ApplicationType[0].c1_1[1]',
            },
            reason: {
              nonUSResident: 'topmostSubform[0].Page1[0].c1_3[0]',
              usResidentAlienTaxReturn: 'topmostSubform[0].Page1[0].c1_4[0]',
              dependentOfUSCitizenResidentAlien: 'topmostSubform[0].Page1[0].c1_5[0]',
              spouseOfUSCitizenResidentAlien: 'topmostSubform[0].Page1[0].c1_6[0]',
              other: 'topmostSubform[0].Page1[0].c1_9[0]',
            },
            gender: {
              male: 'topmostSubform[0].Page1[0].c1_10[0]',
              female: 'topmostSubform[0].Page1[0].c1_10[1]',
            },
          };

          const newAppTypeCheckbox = 'topmostSubform[0].Page1[0].c1_12[0]';
          const renewAppTypeCheckbox = 'topmostSubform[0].Page1[0].c1_12[1]';

          Object.entries(fieldMapping).forEach(([dataKey, fieldName]) => {
            if (data[dataKey] || 
                (dataKey === 'visaDetails' && (data.visaNumber || data.visaExpirationDate)) || 
                (dataKey === 'residentOrSpouseInfo' && (data.residentOrSpouseName || data.residentOrSpouseSsnITIN)) ||
                dataKey === 'itin' ||
                dataKey === 'irsn') {
              try {
                if (dataKey === 'itin' || dataKey === 'irsn') {
                  const numberType = dataKey;
                  const numberFields = fieldName;
                  const numberChars = (data.itin_irs || '').replace(/[^0-9]/g, '').split('');
                  
                  if ((numberType === 'itin' && data.itinIrsnType === 'ITIN') || 
                      (numberType === 'irsn' && data.itinIrsnType === 'IRSN')) {
                    numberFields.forEach((field, index) => {
                      const numberField = form.getTextField(field);
                      if (numberField) {
                        numberField.setText(numberChars[index] || '');
                      }
                    });
                  }
                } else {
                  const field = form.getTextField(fieldName);
                  if (field) {
                    let textToSet = data[dataKey];
                    
                    if (dataKey === 'dateOfBirth' || dataKey === 'entryDate') {
                      textToSet = formatDate(textToSet);
                    }
            
                    if (dataKey === 'countriesOfCitizenship') {
                      textToSet = `${data.countriesOfCitizenship.join(', ')}, ${data.countryOfBirth}`;
                    }
            
                    if (dataKey === 'visaDetails') {
                      textToSet = `${data.visaNumber || ''}, ${data.visaExpirationDate || ''}`.trim();
                      textToSet = textToSet.replace(/^,\s*/, '').replace(/,\s*$/, '');
                    }
            
                    if (dataKey === 'residentOrSpouseInfo') {
                      textToSet = `${data.residentOrSpouseName || ''}, ${data.residentOrSpouseSsnITIN || ''}`.trim();
                      textToSet = textToSet.replace(/^,\s*/, '').replace(/,\s*$/, '');
                    }
            
                    const maxLength = field.getMaxLength();
                    if (maxLength && textToSet.length > maxLength) {
                      field.setText(textToSet.substring(0, maxLength));
                    } else {
                      field.setText(textToSet);
                    }
                  }
                }
              } catch (error) {
                console.error(`Error setting text for field ${fieldName}:`, error);
              }
            }
          });

          Object.entries(checkboxMapping).forEach(([dataKey, options]) => {
            if (data[dataKey]) {
              const fieldName = options[data[dataKey]];
              if (fieldName) {
                try {
                  const field = form.getCheckBox(fieldName);
                  if (field) {
                    field.check();
                  }
                } catch (error) {
                  console.error(`Error setting checkbox for field ${fieldName}:`, error);
                }
              }
            }
          });

          try {
            const field = form.getCheckBox(
              data.applicationType === 'new' ? newAppTypeCheckbox : renewAppTypeCheckbox
            );
            if (field) {
              field.check();
            }
          } catch (error) {
            console.error("Error setting application type checkbox:", error);
          }

          const pdfBytes = await pdfDoc.save();
          const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
          const url = URL.createObjectURL(pdfBlob);
          setPdfUrl(url);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching submission or generating PDF: ", error);
      }
    };

    fetchSubmissionAndGeneratePDF();

    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
    };
  }, [id, firestore]);

  const handleBack = () => navigate(-1);

  const handleOpenDeclineConfirmationDialog = () => setShowDeclineConfirmationDialog(true);

  const handleConfirmDecline = async () => {
    try {
      const submissionDocRef = doc(firestore, 'Submissions', id);
      await updateDoc(submissionDocRef, {
        status: 'Pending',
        caaAgent: deleteField()
      });
      setSubmission(prevState => ({
        ...prevState,
        status: 'Pending',
        caaAgent: undefined
      }));
      setShowDeclineConfirmationDialog(false);
    } catch (error) {
      console.error("Error updating submission status to Pending:", error);
    }
  };

  const handleApprove = () => setShowTrackingDialog(true);

  const handleConfirmTracking = async () => {
    if (!trackingNumber.trim()) {
      alert("Tracking number is required.");
      return;
    }

    try {
      const submissionDocRef = doc(firestore, 'Submissions', id);
      const timestamp = new Date();

      await updateDoc(submissionDocRef, {
        tracking: trackingNumber,
        MailedOn: timestamp,
        status: 'Mailed'
      });

      setSubmission(prevState => ({
        ...prevState,
        tracking: trackingNumber,
        MailedOn: timestamp,
        status: 'Mailed'
      }));

      const duesCollectionRef = collection(firestore, 'dues');
      await addDoc(duesCollectionRef, {
        timestamp: timestamp,
        submissionID: id,
        amount: 150,
        CaaAgent: user.uid
      });

      setShowTrackingDialog(false);
    } catch (error) {
      console.error("Error updating submission with tracking number:", error);
    }
  };

  const handlePrint = () => {
    if (pdfUrl) {
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.style.border = 'none';
      iframe.style.top = '0';
      iframe.style.left = '0';
      iframe.src = pdfUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow.print();
      };
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="submission-detail">
      <div className="topline">
        <button className="back-button" onClick={handleBack}>
          <span className="back-arrow">&larr;</span> Back
        </button>
        <h1 className="page-title">{submission.applicationType}</h1>
        <img src={printerIcon} alt="Print" className="print-icon" onClick={handlePrint} />
        <div className="placeholder"></div>
      </div>

      <div className="contact-info">
        {submission.email && (
          <Chip
            label={`Email: ${submission.email}`}
            variant="outlined"
            className="contact-chip"
          />
        )}
        {submission.phoneNumber && (
          <Chip
            label={`Phone: ${submission.phoneNumber}`}
            variant="outlined"
            className="contact-chip"
          />
        )}
      </div>

      {pdfUrl && (
        <div className="pdf-viewer">
          <Document 
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} />
            ))}
          </Document>
        </div>
      )}

      <div className="approve-group">
        <button className="back-button" onClick={handleOpenDeclineConfirmationDialog}>Decline</button>
        <button className="continue-button" onClick={handleApprove}>Approve</button>
      </div>

      {showTrackingDialog && (
        <div className="tracking-dialog">
          <div className="dialog-content">
            <h3>Enter Tracking Number</h3>
            <input
              type="text"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              placeholder="Tracking Number"
              className="tracking-input"
            />
            <div className="dialog-actions">
              <button className="back-button" onClick={() => setShowTrackingDialog(false)}>Cancel</button>
              <button className="continue-button" onClick={handleConfirmTracking}>Submit</button>
            </div>
          </div>
        </div>
      )}

      {showDeclineConfirmationDialog && (
        <div className="decline-dialog">
          <div className="dialog-content">
            <h3>Confirm Decline</h3>
            <p>Are you sure you want to decline this submission? This will remove the assigned CAA agent and change the status to Pending.</p>
            <div className="dialog-actions">
              <button className="back-button" onClick={() => setShowDeclineConfirmationDialog(false)}>Cancel</button>
              <button className="continue-button" onClick={handleConfirmDecline}>Confirm Decline</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmissionDetail;
