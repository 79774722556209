// src/pages/Premium.js
import React, { useEffect, useState } from 'react';
import './Premium.css'; // You can create this CSS file for custom styling if needed
import { auth, firestore } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Subscribed from './Subscribed';
import Unsubscribed from './Unsubscribed';

const Premium = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const subscriptionsRef = collection(firestore, 'users', userId, 'subscriptions');
      const q = query(subscriptionsRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setIsSubscribed(true);
      }
    };

    fetchSubscriptionStatus();
  }, []);

  return (
    <div className="premium-container">
      {isSubscribed ? <Subscribed /> : <Unsubscribed />}
    </div>
  );
};

export default Premium;
