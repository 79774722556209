import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Tooltip, FormHelperText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { FormContext } from '../../../FormContext';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';

const DependentsInfo = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const { formData, setFormData } = useContext(FormContext);
  const [fileName, setFileName] = useState(formData.fileName || "");

  const fields = [
    'relationship',
    'residentOrSpouseName',
    'residentOrSpouseSsnITIN',
    'schoolRecordUpload',
  ];

  useEffect(() => {
    const fieldErrors = {
      relationship: !formData.relationship ? t('relationshipError') : null,
      residentOrSpouseName: !formData.residentOrSpouseName ? t('nameResidentError') : null,
      residentOrSpouseSsnITIN: !formData.residentOrSpouseSsnITIN ? t('residentOrSpouseSsnITINError') : null,
      schoolRecordUpload: formData.serviceType !== 'rapidW7Completion' && !formData.schoolRecordUpload ? t('schoolRecordUploadError') : null,
    };
  
    const isComplete = Object.values(fieldErrors).every((error) => error === null);
  
    setErrors(fieldErrors);
  }, [formData, t]);

  const handleFileUpload = async (event, field) => {
    const file = event.target.files[0];
    const storage = getStorage();
    const storageRef = ref(storage, `school_record_uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setFileName(file.name);

    try {
      await uploadTask;
      const downloadURL = await getDownloadURL(storageRef);
      setFormData({
        ...formData,
        [field]: downloadURL,
        fileName: file.name,
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleInputChange = (event, field) => {
    const newValue = event.target.value;

    if (field === 'residentOrSpouseSsnITIN') {
      const inputNumber = newValue.replace(/[^0-9-]/g, ''); // Remove non-digit and non-dash characters
      const formattedNumber = formatresidentOrSpouseSsnITINNumber(inputNumber);
      setFormData({ ...formData, residentOrSpouseSsnITIN: formattedNumber });
    } else {
      setFormData({ ...formData, [field]: newValue });
    }
  };

  const formatresidentOrSpouseSsnITINNumber = (number) => {
    // Remove existing dashes
    const digitsOnly = number.replace(/-/g, '');
    // Insert dashes at appropriate positions
    if (digitsOnly.length > 5) {
      return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5, 9)}`;
    } else if (digitsOnly.length > 3) {
      return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
    } else {
      return digitsOnly;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  const nextPage = () => {
    const isFormValid = !Object.values(errors).some((error) => error);
    if (isFormValid) {
      onNext();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('dependentInfo')}</span>
        <Tooltip title={t('infoDependentInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <TextField
        id="relationship-multiline"
        label={t('relationshipToUSCitizenResidentAlien')}
        multiline
        maxRows={4}
        value={formData.relationship}
        onChange={(event) => handleInputChange(event, 'relationship')}
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        required
        error={Boolean(errors.relationship)}
      />
      {errors.relationship && <FormHelperText error>{errors.relationship}</FormHelperText>}

      <TextField
        id="residentOrSpouseName-multiline"
        label={t('nameOfUSCitizenResidentAli')}
        multiline
        maxRows={4}
        value={formData.residentOrSpouseName}
        onChange={(event) => handleInputChange(event, 'residentOrSpouseName')}
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        required
        error={Boolean(errors.residentOrSpouseName)}
      />
      {errors.residentOrSpouseName && <FormHelperText error>{errors.residentOrSpouseName}</FormHelperText>}

      <TextField
        id="residentOrSpouseSsnITIN-multiline"
        label={t('residentOrSpouseSsnITINOfUSCitizenResidentAli')}
        multiline
        maxRows={4}
        value={formData.residentOrSpouseSsnITIN}
        onChange={(event) => handleInputChange(event, 'residentOrSpouseSsnITIN')}
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        required
        error={Boolean(errors.residentOrSpouseSsnITIN)}
      />
      {errors.residentOrSpouseSsnITIN && (
        <FormHelperText error>{errors.residentOrSpouseSsnITIN}</FormHelperText>
      )}

      {formData.serviceType !== 'rapidW7Completion' && (
        <div className="section">
          <div className="header">
            <span className="sub-title">{t('uploadSchoolRecords')}</span>
            <Tooltip title={t('infoSchoolRecords')} arrow>
              <InfoIcon className="info-icon" />
            </Tooltip>
            <input
              className="input-container"
              type="file"
              accept="application/pdf"
              onChange={(event) => handleFileUpload(event, 'schoolRecordUpload')}
              required
              error={Boolean(errors.schoolRecordUpload)}
            />
            {formData.schoolRecordUpload && <div>File: {fileName}</div>}
          </div>
          {errors.schoolRecordUpload && (
            <FormHelperText error>{errors.schoolRecordUpload}</FormHelperText>
          )}
        </div>
      )}

      {formData.serviceType === 'rapidW7Completion' && (
        <div className="section">
          <p>{t('schoolRecordMessage')}</p>
        </div>
      )}

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default DependentsInfo;
