import React from 'react';
import './AdminSettings.css'; // Make sure to create this CSS file

const AdminSettings = () => {
    return (
        <div className="settings-page">
            {/* Your content for the submissions page goes here */}
        </div>
    );
}

export default AdminSettings;
