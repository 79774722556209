import React from 'react';
import '../Form.css';

const lightenColor = (color, percent) => {
  if (!color) return ''; // handle undefined color
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = (num >> 8 & 0x00FF) + amt,
    B = (num & 0x0000FF) + amt;
  return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1)}`;
};

const rgbaColor = (hex, alpha) => {
  if (!hex) return ''; // handle undefined hex
  const num = parseInt(hex.replace("#", ""), 16),
    R = num >> 16,
    G = (num >> 8) & 0x00FF,
    B = num & 0x0000FF;
  return `rgba(${R},${G},${B},${alpha})`;
};

const FormProgressBar = ({ progress, themeColor }) => {
  const defaultThemeColor = '#00C6FF';
  const lighterThemeColor = lightenColor(themeColor || defaultThemeColor, 90);
  const glowColor = rgbaColor(themeColor || defaultThemeColor, 0.3);

  return (
    <div className="progress-bar-container" style={{ background: `linear-gradient(180deg, ${lighterThemeColor} 0%, ${themeColor || defaultThemeColor} 100%)`, boxShadow: `0px 0px 15px ${glowColor}` }}>
      <div className="progress-bar" style={{ width: `${progress}%`, background: `linear-gradient(to right, ${themeColor || defaultThemeColor}, ${lightenColor(themeColor || defaultThemeColor, 60)})` }}></div>
    </div>
  );
};

export default FormProgressBar;
