import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './DashboardHeader.css';
import TextLogo from '../../assets/textlogo.svg';
import DefaultProfilePic from '../../assets/defaultAvatar.png';
import NotificationIcon from './NotificationIcon';
import { useNavigate } from 'react-router-dom';
import useFetchNotifications from '../../hooks/useFetchNotifications'; // Correct path to your hook

const DashboardHeader = ({ title, user }) => {
  const notifications = useFetchNotifications(user?.uid); // Pass the user ID to fetch notifications
  const [profilePicUrl, setProfilePicUrl] = useState(DefaultProfilePic);
  const navigate = useNavigate();
  const firestore = getFirestore();

  useEffect(() => {
    if (user && user.uid) {
      const userRef = doc(firestore, 'users', user.uid);
      getDoc(userRef).then((docSnap) => {
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.profilePictureUrl) {
            setProfilePicUrl(userData.profilePictureUrl);
          }
        }
      }).catch((error) => {
        console.error("Failed to fetch user profile data:", error);
      });
    }
  }, [user, firestore]);

  const handleProfileClick = () => {
    if (user?.role !== 'super_admin') {
      navigate('/CAA/profile');
    }
  };

  return (
    <div className="dashboard-header">
      <img src={TextLogo} alt="Text Logo" className="text-logo" />
      <h2 className="dashboard-title">{title}</h2>
      <div className="dashboard-actions">
      <NotificationIcon notifications={notifications} />
        <img src={profilePicUrl} alt="Profile" className="profile-pic" onClick={handleProfileClick} />
      </div>
    </div>
  );
};

export default DashboardHeader;
