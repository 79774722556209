// DeleteCustomerModal.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const DeleteCustomerModal = ({ open, onClose, onSubmit, submissionId }) => {
  const [reason, setReason] = useState('');

  const handleDelete = () => {
    // Pass the submissionId and reason back to the parent component
    onSubmit(submissionId, reason);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Customer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason for Deletion"
          type="text"
          fullWidth
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="primary">Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomerModal;
