import React, { useState, useEffect, useRef } from 'react';
import venmoLogo from '../../assets/icons/venmo.svg';
import cashappLogo from '../../assets/icons/cashapp.svg';
import lottie from 'lottie-web';
import './component.css';
import { getFirestore, doc, updateDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Tooltip } from '@mui/material'; // Import Tooltip

const PaymentMethodBox = ({ logo, method, placeholder, onSave, isLottie, value, onPreferredChange, isPreferred }) => {
  const [input, setInput] = useState(value || '');
  const [editMode, setEditMode] = useState(false);
  const lottieRef = useRef(null);
  const inputRef = useRef(null);
  const [lottieAnimation, setLottieAnimation] = useState(null);

  useEffect(() => {
    setInput(value || '');
  }, [value]);

  useEffect(() => {
    if (isLottie && lottieRef.current && !editMode) {
      const animation = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('../../assets/animations/phone.json')
      });
      setLottieAnimation(animation);
      return () => animation.destroy();
    }
  }, [isLottie, editMode]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditMode(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const handleSave = () => {
    onSave(method, input);
    setEditMode(false);
  };

  const handleStarClick = (e) => {
    e.stopPropagation();
    onPreferredChange(method);
  };

  const handleBoxClick = () => {
    setEditMode(true);
    if (lottieAnimation) {
      lottieAnimation.destroy();
      setLottieAnimation(null);
    }
  };

  return (
    <div
      className={`payment-box ${editMode ? 'edit-mode' : ''} ${isPreferred ? 'selected' : ''} ${value ? 'saved' : ''}`}
      onClick={handleBoxClick}
      ref={inputRef}
    >
      {!editMode ? (
        <>
          {isLottie ? (
            <div className="lottie-animation" ref={lottieRef} />
          ) : (
            <img src={logo} alt={`${method} logo`} />
          )}
          {value && <div className="saved-value">{value}</div>}
        </>
      ) : (
        <div>
          <Tooltip title="Preferred payment method">
            <div className="star" onClick={handleStarClick}>{isPreferred ? '★' : '☆'}</div>
          </Tooltip>
          <input
            type="text"
            placeholder={placeholder}
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <button className='continue-button' onClick={handleSave}>Save</button>
        </div>
      )}
    </div>
  );
};

const PaymentMethods = () => {
  const [methods, setMethods] = useState({
    Venmo: '',
    CashApp: '',
    Chime: ''
  });
  const [preferredMethod, setPreferredMethod] = useState('');
  const [balanceDue, setBalanceDue] = useState(0);
  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'users', user.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          if (userData.paymentMethods) {
            const fetchedMethods = {};
            let fetchedPreferred = '';
            userData.paymentMethods.forEach(method => {
              fetchedMethods[method.method] = method.value;
              if (method.preferred) {
                fetchedPreferred = method.method;
              }
            });
            setMethods(fetchedMethods);
            setPreferredMethod(fetchedPreferred);
          }
        }
      }
    };

    const fetchBalanceDue = async () => {
      const user = auth.currentUser;
      if (user) {
        const duesQuery = query(collection(firestore, 'dues'), where('submissionID', '==', user.uid), where('paid', '==', false));
        const querySnapshot = await getDocs(duesQuery);
        let totalDue = 0;
        querySnapshot.forEach(doc => {
          totalDue += doc.data().amount;
        });
        setBalanceDue(totalDue);
      }
    };

    fetchPaymentMethods();
    fetchBalanceDue();
  }, [auth, firestore]);

  const handleSave = async (method, value) => {
    setMethods(prev => ({ ...prev, [method]: value }));
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      const paymentMethods = Object.keys(methods).map(m => ({
        method: m,
        value: m === method ? value : methods[m],
        preferred: preferredMethod === m
      }));

      try {
        await updateDoc(userRef, { paymentMethods });
        // Show web notification
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification("Payment Method Saved", {
              body: `Your ${method} payment method has been saved successfully.`
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
              if (permission === "granted") {
                new Notification("Payment Method Saved", {
                  body: `Your ${method} payment method has been saved successfully.`
                });
              }
            });
          }
        }
      } catch (error) {
        console.error('Error saving payment method: ', error);
        alert('Failed to save payment method');
      }
    }
  };

  const handlePreferredChange = async (method) => {
    setPreferredMethod(method);
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);
      const paymentMethods = Object.keys(methods).map(m => ({
        method: m,
        value: methods[m],
        preferred: m === method
      }));

      try {
        await updateDoc(userRef, { paymentMethods });
      } catch (error) {
        console.error('Error setting preferred payment method: ', error);
        alert('Failed to set preferred payment method');
      }
    }
  };

  return (
    <div className="caa-item caa-item-top payment-methods">
      <div className="payment-header">
        <h2 className="payment-title">Your Payment Methods</h2>
        <div className="balance-due">Balance Due: ${balanceDue.toFixed(2)}</div>
      </div>
      <div className="payment-grid">
        <PaymentMethodBox
          logo={venmoLogo}
          method="Venmo"
          placeholder="Username"
          value={methods.Venmo}
          onSave={handleSave}
          onPreferredChange={handlePreferredChange}
          isPreferred={preferredMethod === 'Venmo'}
        />
        <PaymentMethodBox
          logo={cashappLogo}
          method="CashApp"
          placeholder="Username"
          value={methods.CashApp}
          onSave={handleSave}
          onPreferredChange={handlePreferredChange}
          isPreferred={preferredMethod === 'CashApp'}
        />
        <PaymentMethodBox
          method="Chime"
          placeholder="Phone Number"
          value={methods.Chime}
          onSave={handleSave}
          onPreferredChange={handlePreferredChange}
          isLottie
          isPreferred={preferredMethod === 'Chime'}
        />
      </div>
    </div>
  );
};

export default PaymentMethods;
