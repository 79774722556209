import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import googleLogo from '../assets/google.svg';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import logo from '../logo/full-logo.png';
import './AdminLogin.css';
import { firestore } from '../firebase';

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await checkUserRoleAndStatus(userCredential.user);
    } catch (error) {
      setError(error.message);
      console.error('Login Error:', error.message);
    }
  };

  const checkUserRoleAndStatus = async (user) => {
    const { role, onboardingCompleted } = await getRoleForUser(user.uid);
    if (role === 'CAA' && onboardingCompleted) {
      navigate('/CAA');
    } else if (role === 'CAA') {
      navigate('/CAA/onboarding');
    } else if (role === 'super_admin') {
      navigate('/admin');
    } else {
      navigate('/');
    }
  };

  const getRoleForUser = async (userId) => {
    const userRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      return { role: docSnap.data().role, onboardingCompleted: docSnap.data().onboardingCompleted };
    }
    return { role: null, onboardingCompleted: false };
  };

  const handleCloseModal = () => {
    setShowResetPassword(false);
  };

  const handleForgotPassword = async () => {
    if (!resetPasswordEmail) {
      setForgotPasswordError("Please enter your email address.");
      return;
    }

    try {
      const response = await fetch(`https://us-central1-newitin-85fa5.cloudfunctions.net/checkCustomerEmail?email=${encodeURIComponent(resetPasswordEmail)}`);
      const data = await response.json();
  
      if (!data.exists) {
        setForgotPasswordError("No account found with the given email.");
        return;
      }
  
      await sendPasswordResetEmail(auth, resetPasswordEmail);
      setResetPasswordEmail('');
      setShowResetPassword(false);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setForgotPasswordError("Failed to check email. Please try again later.");
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      const userRef = doc(firestore, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          role: 'CAA',
          onboardingCompleted: false,
          caaStatus: null,
        });
        navigate('/CAA/onboarding');
      } else {
        const userData = docSnap.data();
        directNavigationBasedOnUserData(userData);
      }
    } catch (error) {
      console.error('Google Sign Up Error:', error.message);
    }
  };

  const directNavigationBasedOnUserData = (userData) => {
    if (userData.role === 'CAA' && !userData.onboardingCompleted) {
      navigate('/CAA/onboarding');
    } else if (userData.role === 'CAA') {
      navigate('/CAA');
    } else if (userData.role === 'super_admin') {
      navigate('/admin');
    } else {
      navigate('/');
    }
  };

  const isUsernameUnique = async (username) => {
    const firestore = getFirestore();
    const usernameRef = doc(firestore, "usernames", username);
    const docSnap = await getDoc(usernameRef);
    return !docSnap.exists();
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      const randomUsername = `user${Math.floor(1000 + Math.random() * 9000)}`;

      if (await isUsernameUnique(randomUsername)) {
        await setDoc(doc(firestore, "users", userId), {
          email: userCredential.user.email,
          role: 'CAA',
          username: randomUsername,
          approved: false,
          caaStatus: null,
        });
        navigate('/CAA/onboarding');
      } else {
        console.error("Generated username is not unique, try signing up again.");
      }
    } catch (error) {
      console.error("Sign Up Error:", error.message);
      setError(error.message);
    }
  };

  return (
    <div className="auth-container">
      <div className="login-side">
        <div className="login-card">
          <img src={logo} alt='logo' className="login-logo" />
          {isLogin ? (
            <>
              <h1>Welcome Back</h1>
              <label className='auth-label'>Email</label>
              <input
                className="input-login"
                type="email"
                placeholder='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                className="input-login"
                type="password"
                placeholder='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </>
          ) : (
            <>
              <h1>Create New Account</h1>
              <label className='auth-label'>email address</label>
              <input
                className="input-sign"
                type="email"
                placeholder='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                className="input-sign"
                type="password"
                placeholder='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className='auth-label'>Confirm Password</label>
              <input
                className="input-sign"
                type="password"
                placeholder='confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="terms-checkbox">
                <input
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <span>agree to terms</span>
              </div>
            </>
          )}

          {error && <div className="error-message">{error}</div>}

          {isLogin ? (
            <div className="login-actions">
              <button className="continue-button" onClick={onLogin}>
                Login
              </button>
              <a href="#" onClick={() => setShowResetPassword(true)} className="forgot-password-link">
                Forgot password?
              </a>
            </div>
          ) : (
            <button className="continue-button" onClick={handleSignUp}>
              Sign Up
            </button>
          )}

          <div className="or-divider">
            <span>or</span>
          </div>

          <button onClick={handleGoogleSignUp} className="google-button">
            <img src={googleLogo} alt="Google Logo" className="google-logo" />
            Sign Up With Google
          </button>

          {isLogin ? (
            <span>Don't have an account yet? <a href="#" onClick={() => setIsLogin(false)}>Sign Up</a></span>
          ) : (
            <span>Already have an account? <a href="#" onClick={() => setIsLogin(true)}>Login</a></span>
          )}

        </div>
      </div>
      <div className="artwork-section">
        {/* Artwork */}
      </div>
      {showResetPassword && (
        <div className="forgotpassword-overlay" onClick={handleCloseModal}>
          <div className="forgotpassword-container" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleCloseModal}></button>
            <h3>Reset Password</h3>
            <p>Enter your email address and we will send you a link to reset your password.</p>
            <input
              type="email"
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {forgotPasswordError && <div className="error-message">{forgotPasswordError}</div>}
            <div className="forgotpassword-actions">
              <button onClick={handleForgotPassword}>Send Reset Email</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
