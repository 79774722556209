import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, FormHelperText } from '@mui/material';
import { FormContext } from '../../../FormContext';

const OtherReason = ({ onCompletion }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const error = !formData.otherReason ? t('otherReasonError') : null;
    setErrors({ otherReason: error });

    // Trigger completion status update
    onCompletion(!error);
  }, [formData, t, onCompletion]);

  const handleOtherReasonChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      otherReason: event.target.value,
    }));
  };

  return (
    <div className="input-container">
      <TextField
        id="other-reason"
        label={t('otherReason')}
        multiline
        maxRows={4}
        value={formData.otherReason || ''}
        onChange={handleOtherReasonChange}
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        required
        error={Boolean(errors.otherReason)}
      />
      {errors.otherReason && <FormHelperText error>{errors.otherReason}</FormHelperText>}
    </div>
  );
}

export default OtherReason;
