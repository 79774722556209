import React, { useState, useContext } from 'react';
import { TextField, Tooltip, RadioGroup, FormControl, FormControlLabel, Radio } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const ITIN_IRSN = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [fieldError, setFieldError] = useState('');
  const [itinIrsnType, setItinIrsnType] = useState('ITIN'); 

  const handleInputChange = (event) => {
    const inputNumber = event.target.value.replace(/[^0-9-]/g, ''); // Remove non-digit and non-dash characters
    const formattedNumber = formatItinIrsnNumber(inputNumber);
    setFormData(prevFormData => ({
      ...prevFormData,
      itin_irs: formattedNumber,
    }));
    if (fieldError) {
      setFieldError('');
    }
  };

  const formatItinIrsnNumber = (number) => {
    // Remove existing dashes
    const digitsOnly = number.replace(/-/g, '');
    // Insert dashes at appropriate positions
    if (digitsOnly.length > 5) {
      return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5, 9)}`;
    } else if (digitsOnly.length > 3) {
      return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
    } else {
      return digitsOnly;
    }
  };

  const handleItinIrsnTypeChange = (event) => {
    setItinIrsnType(event.target.value);
    setFormData({
      ...formData,
      itinIrsnType: event.target.value,
    });
  };

  const nextPage = () => {
    if (!formData.itin_irs) {
      setFieldError(t('itinIrsnError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('itinIrsn')}</span>
        <Tooltip title={t('infoItinIrsn')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>

      <div className="itin-irsn-switch">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="itin-irsn-type"
            value={itinIrsnType}
            onChange={handleItinIrsnTypeChange}
            row // Display options in a row instead of a column
          >
            <FormControlLabel value="ITIN" control={<Radio />} label={t('ITIN')} />
            <FormControlLabel value="IRSN" control={<Radio />} label={t('IRSN')} />
          </RadioGroup>
        </FormControl>
      </div>

      <TextField
        label={itinIrsnType === 'ITIN' ? t('ITIN Number') : t('IRSN Number')}
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        onChange={handleInputChange}
        value={formData.itin_irs || ''}
        required
        error={Boolean(fieldError)}
        helperText={fieldError}
      />

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default ITIN_IRSN;
