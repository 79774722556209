import React, { useRef, useState, useContext } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Info as InfoIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';
import Tooltip from '@mui/material/Tooltip';
import { handlePurchase } from '../../../hooks/helper';
import FormReview from '../../components/FormReview';

const Signature = ({ onNext, onBack, onSubmit, themeColor }) => {
  const { t } = useTranslation();
  const sigCanvas = useRef({});
  const { formData, setFormData } = useContext(FormContext);
  const [fieldError, setFieldError] = useState('');
  const [showFormReview, setShowFormReview] = useState(false);
  
  const saveSignature = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setFormData(prevFormData => ({
      ...prevFormData,
      signature: dataUrl,
    }));
    if (fieldError) setFieldError('');
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setFormData(prevFormData => ({
      ...prevFormData,
      signature: null,
    }));
  };

  const handleEdit = () => {
    setShowFormReview(false);
    onBack();
  };

  const nextPage = () => {
    if (!formData.signature) {
      setFieldError(t('errorSignatureRequired'));
      return;
    }

    setShowFormReview(true); 
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="switcher" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('signHere')}</span>
        <Tooltip title={t('infoSignature')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ className: 'signature-canvas' }}
      />
      <div className="signature-actions">
        <button className="save-signature" onClick={saveSignature}>{t('saveSignature')}</button>
        <button className="clear-signature" onClick={clearSignature}>{t('clearSignature')}</button>
      </div>
      {fieldError && <div className="error-message">{fieldError}</div>}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>{t('backButton')}</button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>{t('continueButton')}</button>
      </div>
      
      {showFormReview && (
        <FormReview
          onEdit={handleEdit}
          onPurchase={() => handlePurchase(formData)}
          onClose={() => {
            setShowFormReview(false);
            onSubmit();
          }}
        />
      )}
    </div>
  );
};

export default Signature;
