import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Switch from '@mui/material/Switch';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import './EditProfileForm.css';

const EditProfileForm = ({ userProfile, setUserProfile, setIsEditing }) => {
  const [formData, setFormData] = useState({ ...userProfile });
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const auth = getAuth();
  const firestore = getFirestore();
  const storage = getStorage();
  const [errors, setErrors] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(userProfile.languages || []);
  const [selectedContentRequirements, setSelectedContentRequirements] = useState(Array.isArray(userProfile.contentRequirements) ? userProfile.contentRequirements : []);

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();
        const options = data
          .filter((country) => country.name.common !== 'United States')
          .map((country) => ({ key: country.cca2, label: country.name.common }));
        setCountryOptions(options);
      } catch (error) {
        console.error('Error fetching country options:', error);
      }
    };
    fetchCountryOptions();
  }, []);

  const isValidUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
  };

  useEffect(() => {
    if (JSON.stringify(formData) !== JSON.stringify(userProfile)) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [formData, userProfile]);

  useEffect(() => {
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      let occurrencesInA = a.reduce((acc, val) => (acc[val] = (acc[val] || 0) + 1, acc), {});
      let occurrencesInB = b.reduce((acc, val) => (acc[val] = (acc[val] || 0) + 1, acc), {});

      for (let val in occurrencesInA) {
        if (!(val in occurrencesInB) || occurrencesInA[val] !== occurrencesInB[val]) {
          return false;
        }
      }

      return true;
    };

    const hasLanguagesChanged = !arraysEqual(selectedLanguages, userProfile.languages || []);
    const hasContentRequirementsChanged = !arraysEqual(selectedContentRequirements, userProfile.contentRequirements || []);
    const hasSelectedCountriesChanged = !arraysEqual(formData.selectedCountries || [], userProfile.selectedCountries || []);

    const formHasChanged = JSON.stringify(formData) !== JSON.stringify(userProfile) || hasLanguagesChanged || hasContentRequirementsChanged || hasSelectedCountriesChanged;

    setHasChanged(formHasChanged);
  }, [formData, userProfile, selectedLanguages, selectedContentRequirements]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prevFormData => ({ ...prevFormData, [name]: newValue }));

    if (errors[name]) {
        const newErrors = { ...errors };
        delete newErrors[name];
        setErrors(newErrors);
    }

    if (name === "website") {
        if (!isValidUrl(value) && value.trim() !== '') {
            setErrors(prevErrors => ({ ...prevErrors, website: "Please enter a valid URL." }));
        } else {
            const updatedErrors = { ...errors };
            delete updatedErrors.website;
            setErrors(updatedErrors);
        }
    }

    setHasChanged(true);
  };

  const handleProfilePictureChange = (e) => {
    setFile(e.target.files[0]);
    setHasChanged(true);
  };

  const uploadProfilePicture = async () => {
    if (!file) return null;
    const storageRef = ref(storage, `profile_pictures/${auth.currentUser.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        () => {},
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const saveProfile = async () => {
    if (!hasChanged) {
      setIsEditing(false);
      return;
    }

    setIsLoading(true);

    let downloadURL = formData.profilePictureUrl;
    if (file) {
      try {
        downloadURL = await uploadProfilePicture();
        formData.profilePictureUrl = downloadURL;
      } catch (error) {
        console.error("Profile picture upload failed:", error);
        setIsLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, profilePicture: "Failed to upload profile picture." }));
        return;
      }
    }

    const updatedData = {
      ...formData,
      languages: selectedLanguages,
      contentRequirements: selectedContentRequirements,
      selectedCountries: formData.selectedCountries,
    };

    const userRef = doc(firestore, 'users', auth.currentUser.uid);
    try {
      await setDoc(userRef, updatedData, { merge: true });
      setUserProfile(updatedData);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document: ", error);
      setErrors(prevErrors => ({ ...prevErrors, general: "Failed to update profile. Please try again." }));
    }

    setIsLoading(false);
  };

  const handleCountryChange = (event, newValue) => {
    const selectedCountryCodes = newValue.map((item) => item.key);
    setFormData(prevFormData => ({ ...prevFormData, selectedCountries: selectedCountryCodes }));
    setHasChanged(true);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const availableContentRequirements = ['W7', 'eSignature', 'form1040', 'passportScan', 'other'];
  const languageOptions = ['English', 'Spanish', 'French', 'German', 'Italian', 'Hindi'];
  const filter = createFilterOptions();

  return (
    <div className="edit-profile-form">
      <div className="form-group full-width">
        <label htmlFor="bio">Bio (250 characters max):</label>
        <textarea id="bio" name="bio" maxLength="250" value={formData.bio} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="profilePicture">New Profile Picture:</label>
        <input type="file" id="profilePicture" onChange={handleProfilePictureChange} />
      </div>
  
      <div className="form-group">
        <label htmlFor="username">Username:</label>
        <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} />
      </div>

      <div className="form-group">
        <label htmlFor="email">Public Email:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
      </div>
  
      <div className="form-group">
        <label htmlFor="businessName">Business Name:</label>
        <input type="text" id="businessName" name="businessName" value={formData.businessName} onChange={handleChange} />
      </div>
  
      <div className="form-group">
        <label htmlFor="website">Website:</label>
        <TextField 
          type="url" 
          id="website" 
          name="website" 
          value={formData.website} 
          onChange={handleChange} 
          error={!!errors.website}
          helperText={errors.website}
          variant="outlined"
          fullWidth
        />
      </div>

      <div className="form-group">
        <label htmlFor="country">Country:</label>
        <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} />
      </div>
  
      <div className="form-group">
        <label htmlFor="state">State:</label>
        <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} />
      </div>
  
      <div className="form-group">
        <label htmlFor="isOpenToAll">Open to All:</label>
        <Switch
          checked={formData.isOpenToAll}
          onChange={handleChange}
          name="isOpenToAll"
        />
      </div>

      {!formData.isOpenToAll && (
        <div className="form-group">
          <label htmlFor="selectedCountries">Service Areas:</label>
          <Autocomplete
            multiple
            id="country-select"
            options={countryOptions}
            getOptionLabel={(option) => option.label}
            value={formData.selectedCountries.map(code => countryOptions.find(option => option.key === code)).filter(Boolean)}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select countries"
                error={!!errors.selectedCountries}
                helperText={errors.selectedCountries}
              />
            )}
          />
        </div>
      )}
  
      <div className="form-group">
        <label htmlFor="contentRequirements">ITIN Application Contents:</label>
        <Autocomplete
          multiple
          id="content-requirements-select"
          options={availableContentRequirements}
          value={Array.isArray(selectedContentRequirements) ? selectedContentRequirements : []}
          onChange={(event, newValue) => {
            setSelectedContentRequirements(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '') {
              filtered.push(params.inputValue);
            }
            return filtered;
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...otherProps } = getTagProps({ index });
              return <Chip key={option} label={option} {...otherProps} />;
            })
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Add requirements" placeholder="Type or select" />
          )}
        />
      </div>

      <div className="form-group">
        <label htmlFor="languages">Languages Spoken:</label>
        <Autocomplete
          multiple
          id="languages-select"
          options={languageOptions}
          value={selectedLanguages}
          onChange={(event, newValue) => {
            setSelectedLanguages(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '' && !filtered.some(option => typeof option === 'string' && option.toLowerCase() === params.inputValue.toLowerCase())) {
              filtered.push(params.inputValue);
            }
            return filtered;
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...otherProps } = getTagProps({ index });
              return <Chip key={option} label={option} {...otherProps} />;
            })
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Select or add languages" placeholder="Type or select" />
          )}
        />
      </div>
  
      <div className="action-buttons full-width">
        <button
          type="button"
          className="back-button"
          onClick={cancelEdit}
          disabled={isLoading}
        >
          Cancel
        </button>
        {hasChanged && (
          <button
            type="button"
            className="continue-button"
            onClick={saveProfile}
            disabled={isLoading}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default EditProfileForm;
