import React, { useState } from 'react';
import { firestore } from '../../firebase'; // Ensure this path matches your project structure
import { doc, collection, addDoc } from 'firebase/firestore';
import { Rating, Typography, FormControl, InputLabel, Select, MenuItem, TextareaAutosize, Box } from '@mui/material';
import Button from '@mui/material/Button';
import './reviewCAA.css'; // Assuming you have a CSS file for styling

const ReviewForm = ({ caaAgentId, submissionId }) => {
  const [applicationApproved, setApplicationApproved] = useState('');
  const [rating, setRating] = useState(2);
  const [comments, setComments] = useState('');
  const [reviewSubmitted, setReviewSubmitted] = useState(false); // New state to track if the review has been submitted

  const handleSubmit = async (event) => {
    event.preventDefault();

    const ratingsRef = collection(firestore, `users/${caaAgentId}/ratings`);
    await addDoc(ratingsRef, {
      submissionId,
      applicationApproved,
      rating,
      comments,
      createdAt: new Date(),
    });

    setApplicationApproved('');
    setRating(2);
    setComments('');
    setReviewSubmitted(true); // Update state to indicate review submission
  };

  if (reviewSubmitted) {
    // Display success message instead of the form
    return (
      <div className="review-form-container">
        <Typography variant="h5" gutterBottom>Thank you for submitting your review!</Typography>
        <Typography>We appreciate your feedback.</Typography>
      </div>
    );
  }

  // Return the review form if not yet submitted
  return (
    <div className="review-form-container">
      <Typography variant="h6" gutterBottom>Submit CAA Review</Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="normal">
            <p> Was your application approved?</p>
          <Select
            value={applicationApproved}
            onChange={(e) => setApplicationApproved(e.target.value)}
            required
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
        <Box marginY={2}>
          <Typography component="legend">Rate your CAA agent:</Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Box>
        <FormControl fullWidth margin="normal">
          <Typography component="legend">Comments</Typography>
          <TextareaAutosize
            minRows={4}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Add any additional comments here"
            style={{ width: '100%' }}
          />
        </FormControl>
        <button type="submit" variant="contained" color="primary" className='continue-button'>Submit Review</button>
      </form>
    </div>
  );
};

export default ReviewForm;
