import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import './component.css'; // Ensure this path is correct for your styling

// Lottie animation paths
const steps = [
    {
      text: 'Customer Completes ITIN Application',
      animationData: require('../../assets/animations/start.json'),
      backgroundColor: '#E9FFFE',
    },
    {
      text: 'Route Application to Agent',
      animationData: require('../../assets/animations/connecting.json'),
      backgroundColor: '#99FFF3',
    },
    {
      text: 'Complete ITIN Application',
      animationData: require('../../assets/animations/completed.json'),
      backgroundColor: '#00F0FF',
    },
    {
      text: 'Receive Completion Payment',
      animationData: require('../../assets/animations/checkout.json'),
      backgroundColor: '#D2FFFF',
    },
];

const StepGuide = () => {
  const stepRefs = useRef([]);

  useEffect(() => {
    const animations = stepRefs.current.map((ref, index) => {
      if (ref) {
        return lottie.loadAnimation({
          container: ref, // the DOM element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: steps[index].animationData,
        });
      }
      return null;
    });
    return () => animations.forEach(animation => animation && animation.destroy());
  }, []);

  const handleStepClick = () => {
    window.location.href = 'https://www.itin.support/certified-acceptance-agent';
  };

  return (
    <div className='caa-item caa-item-bottom'>
      <div className="step-guide">
        {steps.map((step, index) => (
          <div key={index} className="step" style={{ backgroundColor: step.backgroundColor }} onClick={handleStepClick}>
            <div className="step-text">{step.text}</div>
            <div className="animation-container" ref={el => stepRefs.current[index] = el}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepGuide;
