import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useReward } from 'react-rewards';
import './Form.css';

const CancelPage = () => {
  const { t } = useTranslation();
  const firestore = getFirestore(); // Create a Firestore instance

  // Retrieve the docId from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const docId = urlParams.get('session_id');
  const paymentPageURL = `https://checkout.stripe.com/c/pay/${docId}`; // Adjust as needed.

  const { reward, isAnimating } = useReward('rewardId', 'emoji', {
    lifetime: 1000,
    angle: 270, // Adjust angle to 270 degrees
    decay: 0.94,
    spread: 105,
    startVelocity: 10,
    elementCount: 10,
    elementSize: 25,
    zIndex: 0,
    position: "fixed",
    emoji: ['😢', '😞', '😭']
  });

  const [hasRewarded, setHasRewarded] = useState(false);

  useEffect(() => {
    // Update Firestore document with paymentError = true
    const docRef = doc(firestore, 'Submissions', docId);
    updateDoc(docRef, { paymentError: true })
      .then(() => {
        console.log('Firestore document updated with paymentError = true');
      })
      .catch((error) => {
        console.error('Error updating Firestore document:', error);
      });

    // Trigger the reward function when component mounts, but only once
    if (!hasRewarded) {
      reward();
      setHasRewarded(true);
    }
  }, [docId, firestore, reward, hasRewarded]);

  return (
    <div className="success">
      <h1 className="page-title">{t('paymentError')}</h1>
      <p className="confirmation-text">{t('errorMsg')}</p>
      <p className="submissionID">{t('submissionId')} {docId}</p>
      <p className="confirmation-text">{t('pleaseCall')}</p>
      <a href="https://calendly.com/itin-support/15min" target="_blank" rel="noopener noreferrer">
          <button className="back-button">
              Book Appointment
          </button>
      </a>
      <a href="https://app.itin.support/itin" target="_blank" rel="noopener noreferrer">  {/* Update this line */}
          <button className="continue-button">
              Try Again
          </button>
      </a>
      <span id="rewardId" style={{position: 'fixed', top: 0, left: '50%'}} /> {/* Adjust placement to top */}
    </div>
  );
};

export default CancelPage;
