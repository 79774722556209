import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Avatar, Chip } from '@mui/material';
import { getFirestore, doc, updateDoc, deleteDoc } from 'firebase/firestore';

const UserProfileModal = ({ open, onClose, userProfile }) => {
  const [declineReason, setDeclineReason] = useState('');
  const [isDeclining, setIsDeclining] = useState(false);
  const firestore = getFirestore();

  if (!userProfile) {
    return null;
  }

  const handleApprove = async () => {
    const userRef = doc(firestore, 'users', userProfile.id);
    await updateDoc(userRef, { caaStatus: true });
    onClose();
  };

  const handleDecline = async () => {
    if (!declineReason.trim()) {
      alert('Please provide a reason for decline.');
      return;
    }
    try {
      // Attempt to delete the user
      await deleteDoc(doc(firestore, 'users', userProfile.id));
    } catch (error) {
      console.error("Error deleting user:", error);
      // If deletion fails, update the user's status
      const userRef = doc(firestore, 'users', userProfile.id);
      await updateDoc(userRef, {
        caaStatus: false,
        onboardingCompleted: false,
        declineReason: declineReason,
      });
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>User Profile</DialogTitle>
      <DialogContent dividers>
        <div>
          <Avatar alt={userProfile.username} src={userProfile.profilePictureUrl || '/default-profile.png'} className="profile-picture" />
          <strong>First Name:</strong> {userProfile.firstName}<br />
          <strong>Last Name:</strong> {userProfile.lastName}<br />
          <strong>Username:</strong> {userProfile.username}<br />
          <strong>Email:</strong> {userProfile.email}<br />
          <strong>Country:</strong> {userProfile.country}<br />
          <strong>State:</strong> {userProfile.state}<br />
          <strong>Business Name:</strong> {userProfile.businessName}<br />
          <strong>Business Website:</strong> {userProfile.website}<br />
          <strong>CAA License URL:</strong> <a href={userProfile.caaLicenseUrl} target="_blank" rel="noopener noreferrer">View License</a><br />
          <strong>Languages Spoken:</strong> {userProfile.languages && userProfile.languages.map(language => <Chip key={language} label={language} />)}<br />
          <strong>Content Requirements:</strong> {userProfile.contentRequirements && userProfile.contentRequirements.map(requirement => <Chip key={requirement} label={requirement} />)}<br />
          <strong>Other Content:</strong> {userProfile.otherContent}<br />
        </div>
        {isDeclining && (
          <TextField
            label="Reason for Decline"
            multiline
            rows={4}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
            fullWidth
            variant="outlined"
            margin="dense"
          />
        )}
      </DialogContent>
      <DialogActions>
        {isDeclining ? (
          <>
            <Button onClick={() => setIsDeclining(false)}>Cancel</Button>
            <Button onClick={handleDecline} color="error">Submit Decline</Button>
          </>
        ) : (
          <>
            <Button onClick={() => setIsDeclining(true)} color="error">Decline</Button>
            <Button onClick={handleApprove} color="primary">Approve</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserProfileModal;