import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FirstName from '../questions/ITIN/FirstName';
import LastName from '../questions/ITIN/LastName';
import PhoneNumber from '../questions/ITIN/PhoneNumber';
import CountryOfBirth from '../questions/ITIN/CountryOfBirth';
import DateOfBirth from '../questions/ITIN/DateOfBirth';
import Gender from '../questions/ITIN/Gender';
import CountriesOfCitizenship from '../questions/ITIN/CountriesOfCitizenship';
import ForeignTaxID from '../questions/ITIN/ForeignTaxID';
import Reasoning from '../questions/ITIN/Reasoning';
import Visa from '../questions/ITIN/Visa';
import ApplicationType from '../questions/ITIN/ApplicationType';
import Address from '../questions/ITIN/Address';
import EntryDate from '../questions/ITIN/EntryDate';
import ForeignAddress from '../questions/ITIN/ForeignAddress';
import Signature from '../questions/ITIN/Signature';
import ITINIRSN from '../questions/ITIN/ITINIRSN';
import TaxQuestion from '../questions/ITIN/TaxQuestion';
import Spouse from '../questions/ITIN/Spouse';
import DependentsInfo from '../questions/ITIN/DependentsInfo';
import FormProgressBar from '../components/FormProgressBar';
import FormReview from '../components/FormReview';
import { FormContext } from '../../FormContext';
import { handlePurchase } from '../../hooks/helper';
import { addDoc, collection } from 'firebase/firestore';
import { firestore } from '../../firebase';

const MultiStepForm = ({ themeColor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [applicationType, setApplicationType] = useState('');
  const [progress, setProgress] = useState(0);
  const { formData } = useContext(FormContext);
  const [showFormReview, setShowFormReview] = useState(false);

  const onNext = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const onBack = useCallback(() => {
    setStep((prevStep) => prevStep - 1);
  }, []);

  const setApplication = (type) => {
    setApplicationType(type);
    onNext();
  };

  const handleEdit = () => {
    setShowFormReview(false);
    onBack();
  };

  const handleSuccess = async () => {
    try {
      if (formData.serviceType === 'caaApplication') {
        await addDoc(collection(firestore, 'users', formData.userId, 'submissions'), formData);
        navigate(`/public/${formData.userId}?success=true`);
      } else {
        await handlePurchase(formData);
      }
    } catch (error) {
      console.error('Error saving submission:', error);
    }
  };  

  const commonSteps = [
    () => <FirstName onNext={onNext} onBack={onBack} />,
    () => <LastName onNext={onNext} onBack={onBack} />,
    () => <PhoneNumber onNext={onNext} onBack={onBack} />,
    () => <CountryOfBirth onNext={onNext} onBack={onBack} />,
    () => <DateOfBirth onNext={onNext} onBack={onBack} />,
    () => <Gender onNext={onNext} onBack={onBack} />,
    () => <CountriesOfCitizenship onNext={onNext} onBack={onBack} />,
    () => <ForeignTaxID onNext={onNext} onBack={onBack} />,
    () => <Visa onNext={onNext} onBack={onBack} />,
    () => <ApplicationType onNext={onNext} onBack={onBack} setApplication={setApplication} />
  ];

  const getDynamicSteps = () => {
    let dynamicSteps = [];
    if (formData.reason === 'spouseOfUSCitizenResidentAlien') {
      dynamicSteps.push(() => <Spouse onNext={onNext} onBack={onBack} />);
    } else if (formData.reason === 'dependentOfUSCitizenResidentAlien') {
      dynamicSteps.push(() => <DependentsInfo onNext={onNext} onBack={onBack} />);
    }
    return dynamicSteps;
  };

  const newITINSteps = [
    ...commonSteps,
    () => <Reasoning onNext={onNext} onBack={onBack} />,
    ...getDynamicSteps(),
    () => <EntryDate onNext={onNext} onBack={onBack} />,
    formData.hasArrivedInUS ? () => <Address onNext={onNext} onBack={onBack} /> : () => <ForeignAddress onNext={onNext} onBack={onBack} />,
    formData.serviceType === 'caaApplication'
      ? () => <FormReview onEdit={handleEdit} onClose={handleSuccess} />
      : () => <Signature onNext={onNext} onBack={onNext} />
  ].filter(Boolean);

  const renewITINSteps = [
    ...commonSteps,
    () => <ITINIRSN onNext={onNext} onBack={onBack} />,
    formData.serviceType === 'caaApplication'
      ? () => <FormReview onEdit={handleEdit} onClose={handleSuccess} />
      : () => <TaxQuestion onNext={onNext} onBack={onBack} />,
    formData.serviceType !== 'caaApplication' && (() => <Signature onNext={onNext} onBack={onBack} />)
  ].filter(Boolean);

  let steps;
  if (applicationType === 'new') {
    steps = newITINSteps;
  } else if (applicationType === 'renew') {
    steps = renewITINSteps;
  } else {
    steps = commonSteps;
  }

  useEffect(() => {
    let totalSteps;
    if (applicationType === '') {
      totalSteps = commonSteps.length + 1;
      setProgress((step + 1) * 50 / totalSteps);
    } else {
      totalSteps = steps.length;
      const additionalSteps = totalSteps - commonSteps.length;
      setProgress(50 + ((step - commonSteps.length + 1) * 50 / additionalSteps));
    }
  }, [step, steps.length, commonSteps.length, applicationType]);

  return (
    <>
      <FormProgressBar progress={progress} themeColor={themeColor} />
      {steps[step] ? steps[step]() : null}
      {showFormReview && (
        <FormReview
          onEdit={handleEdit}
          onPurchase={() => handlePurchase(formData)}
          onClose={handleSuccess}
        />
      )}
    </>
  );
};

export default MultiStepForm;
