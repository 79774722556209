// PublicITINForm.js
import React, { useContext, useState, useEffect } from 'react';
import MultiStepForm from './components/MultiStepForm';
import { FormContext } from '../FormContext';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const PublicITINForm = () => {
  const { userId } = useParams();
  const { formData, setFormData } = useContext(FormContext);
  const [customization, setCustomization] = useState({ logoUrl: '', themeColor: '#000000' });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStyles = async () => {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        setCustomization({
          logoUrl: data.logoUrl || '',
          themeColor: data.themeColor || '#000000'
        });
      }
    };

    fetchStyles();
    setFormData({ ...formData, userId, serviceType: 'caaApplication' });
  }, [userId, setFormData]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success') === 'true') {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        navigate(`/public/${userId}`);
      }, 3000); // Display the success message for 3 seconds and then navigate back to the home page
    }
  }, [userId, navigate]);

  return (
    <div>
      <div className="homepage">
        <MultiStepForm themeColor={customization.themeColor} />
      </div>
    </div>
  );
};

export default PublicITINForm;
