import React from 'react';
import { TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';

const DateOfBirth = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = React.useContext(FormContext);
  const [fieldError, setFieldError] = React.useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      dateOfBirth: value,
    }));
    if (fieldError) {
      setFieldError('');
    }
  };

  const nextPage = () => {
    if (!formData.dateOfBirth) {
      setFieldError(t('dateOfBirthError'));
      return;
    }

    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('basicInfo')}</span>
        <Tooltip title={t('infoBasicInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div className="sub-sub-title">{t('dateOfBirth')}</div>
      <TextField
        label={t('dateSelect')}
        type="date"
        variant="standard"
        className="input-field"
        style={{ marginBottom: '10px' }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleInputChange}
        value={formData.dateOfBirth || ''}
        required
        error={Boolean(fieldError)}
        helperText={fieldError}
      />
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default DateOfBirth;