import React, { useContext, useState } from 'react';
import { TextField, Switch, FormControlLabel, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext'; // Adjust the path based on your context location

const EntryDate = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [fieldError, setFieldError] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      entryDate: value,
    }));
    if (fieldError) {
      setFieldError('');
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked,
      entryDate: checked ? prevState.entryDate || '' : '',
    }));
  };

  const nextPage = () => {
    if (formData.hasArrivedInUS && (formData.entryDate === '' || formData.entryDate == null)) {
      setFieldError(t('entryDateError'));
      return;
    }
    onNext();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="switcher" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">New ITIN</span>
        <Tooltip title={t('infoArrival')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
        <div className="sub-sub-title">{t('arrivedInUS')}</div>
        <Box> {/* Add left margin to the switch */}
          <FormControlLabel
            control={
              <Switch 
                checked={formData.hasArrivedInUS} 
                onChange={handleSwitchChange} 
                name="hasArrivedInUS" 
              />
            }
            label={formData.hasArrivedInUS ? t('yes') : t('no')}
          />
        </Box>
      {formData.hasArrivedInUS && (
        <TextField
          name="entryDate"
          value={formData.entryDate || ''}
          onChange={handleInputChange}
          variant="standard"
          className="input-field"
          label={t('entryDate')}
          required={formData.hasArrivedInUS}
          error={Boolean(fieldError) && formData.hasArrivedInUS}
          helperText={fieldError}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>

        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default EntryDate;
