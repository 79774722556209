import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';


const ApplicationType = ({ onBack, setApplication, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [applicationTypeError, setApplicationTypeError] = useState('');

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, applicationType: value });
  };

  const nextPage = () => {
    if (!formData.applicationType) {
      setApplicationTypeError(t('applicationTypeError'));
    } else {
      setApplicationTypeError('');
      setApplication(formData.applicationType);
    }
  };

  return (
    <div className="application-type">
      <div className="header">
        <span className="sub-title">{t('applicationType')}</span>
        <Tooltip title={t('applicationInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>

      <div className="radio-option">
        <label>
          <input
            type="radio"
            name="applicationType"
            value="new"
            checked={formData.applicationType === 'new'}
            onChange={handleInputChange}
          />
          {t('applyNewITIN')}
        </label>
      </div>

      <div className="radio-option">
        <label>
          <input
            type="radio"
            name="applicationType"
            value="renew"
            checked={formData.applicationType === 'renew'}
            onChange={handleInputChange}
          />
          {t('renewITIN')}
        </label>
      </div>

      {applicationTypeError && <p className="error-message">{applicationTypeError}</p>} 

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default ApplicationType;
