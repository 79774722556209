import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Ensure this import points to your firebase init file
import axios from 'axios';

export const handlePurchase = async (formData) => {
  try {
    const timestamp = new Date(); // Get the current timestamp
    const formDataWithTimestamp = { ...formData, timestamp }; // Add timestamp to form data

    const docRef = await addDoc(collection(firestore, "Submissions"), formDataWithTimestamp);
    console.log("Document written with ID: ", docRef.id);

    const priceId = formData.serviceType === 'rapidW7Completion' ? 'price_1PanwuJu54yrucEthefeVJ40' : 'price_1ParntJu54yrucEtMmKSgFF2'; // Ensure these price IDs exist in Stripe

    const response = await axios.post(
      "https://us-central1-newitin-85fa5.cloudfunctions.net/app/create-checkout-session",
      { docId: docRef.id, priceId } // Use your actual priceId here
    );

    // Redirect to the checkout page
    window.location.href = response.data.url;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    alert('Failed to create checkout session');
  }
};
