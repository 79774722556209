import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './Profile.css';
import editIcon from '../assets/icons/edit.svg'; 
import licenseIcon from '../assets/license.png'; 
import EditProfileForm from './components/EditProfileForm';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../pages/components/Loader'

const UserProfile = () => {
  const { userId } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const firestore = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async (uid) => {
      const userRef = doc(firestore, 'users', uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        setUserProfile(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    const currentUserId = userId || auth.currentUser?.uid;
    if (currentUserId) {
      fetchUserData(currentUserId);
    }
  }, [userId, firestore, auth]);

  if (!userProfile) {
    return <Loader />;
  }

  const toggleEdit = () => setIsEditing(!isEditing);

  return (
    <div className="user-profile">
      <div className="top-section">
        <div className="left-box">
          <img src={userProfile.profilePictureUrl || '/default-profile.png'} alt="Profile" className="profile-picture" />
          <div className="name-username-location">
            <h1>{`${userProfile.firstName || 'First'} ${userProfile.lastName || 'Lastname'}`}</h1>
            <p className="username">{userProfile.username ? `@${userProfile.username}` : 'No username'}</p>
            <p className="location">{`${userProfile.state || ''}, ${userProfile.country || ''}`}</p>
          </div>
        </div>
        <div className="right-box">
          <Tooltip title="Edit Profile" arrow>
            <img src={editIcon} alt="Edit Profile" className="edit-icon" onClick={toggleEdit} />
          </Tooltip>
          <Tooltip title="View CAA Certificate" arrow>
            <a href={userProfile.caaLicenseUrl} target="_blank" rel="noopener noreferrer">
              <img src={licenseIcon} alt="CAA License" className="caa-license" />
            </a>
          </Tooltip>
        </div>
      </div>
      <div className="info-section">
        {isEditing ? (
          <EditProfileForm
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            setIsEditing={setIsEditing}
          />
        ) : (
          <>
            <div className="info-item">
              <strong>Bio:</strong> <Chip label={userProfile.bio || 'Your bio here'} />
            </div>
            <div className="info-item">
              <strong>Website:</strong> <Chip label={userProfile.website || 'N/A'} />
            </div>
            <div className="info-item">
              <strong>Email:</strong> <Chip label={userProfile.email || 'example@email.com'} />
            </div>
            <div className="info-item">
              <strong>Service Areas:</strong>
              {userProfile.isOpenToAll ? (
                <Chip label="Global" />
              ) : (
                userProfile.selectedCountries && userProfile.selectedCountries.map((country, index) => (
                  <Chip key={index} label={country} />
                ))
              )}
            </div>
            <div className="info-item">
              <strong>Contents Needed for Approval:</strong>
              {Array.isArray(userProfile.contentRequirements) ? (
                userProfile.contentRequirements.map((contentRequirement, index) => (
                  <Chip key={index} label={contentRequirement} style={{ marginRight: "8px", marginBottom: "8px" }} />
                ))
              ) : (
                <Chip label="No requirements specified" style={{ marginRight: "8px", marginBottom: "8px" }} />
              )}
            </div>
            <div className="info-item">
              <strong>Languages Spoken:</strong>
              {userProfile.languages && userProfile.languages.map((language, index) => (
                <Chip key={index} label={language} />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
