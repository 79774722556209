import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Form.css';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { FormContext } from '../FormContext';
import { collection, addDoc, getDocs } from "firebase/firestore";
import { firebaseConfig } from '../firebase';  
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import logo from '../assets/textlogo.svg';
import Header from "./components/Header";
import Footer from "./components/Footer";

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  
  const { formData, setFormData } = useContext(FormContext);
  const [email, setEmail] = useState(formData.email || '');
  const [emailError, setEmailError] = useState('');

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    setFormData({
      email: '',
    });
  }, [setFormData]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (emailError) {
      setEmailError('');
    }
  };

  const checkEmailExists = async (email) => {
    const emailsRef = collection(firestore, "Emails");
    const querySnapshot = await getDocs(emailsRef);
    const emails = querySnapshot.docs.map((doc) => doc.data().email);
    return emails.includes(email);
  };

  const startApplication = async (serviceType, serviceProduct) => {
    if (email === '' || !validateEmail(email)) {
      setEmailError(t('invalidEmail'));
      return;
    }

    const emailExists = await checkEmailExists(email);

    if (!emailExists) {
      try {
        await addDoc(collection(firestore, "Emails"), { email });
      } catch (error) {
        console.error("Error adding document:", error);
      }
    }

    setFormData({
      ...formData,
      email,
      serviceType,
      serviceProduct,  // Add serviceProduct to formData
    });

    navigate('/itin'); 
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div>
      <Header />
      <div className="home-section">
        <img src={logo} alt="ITIN Support Logo" className="logo-home" />
        <div className="languages-section">
          <select className="language-dropdown" value={i18n.language} onChange={changeLanguage}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="cn">中文</option>
            <option value="fn">Français</option>
            <option value="hn">हिन्दी भाषा</option>
          </select>
        </div>
        <h1 className="intro-text">{t('introText')}</h1>
        <TextField
          label={t('provideEmailAddress')}
          variant="standard"
          className="home-field"
          style={{ marginBottom: '10px' }}
          onChange={handleEmailChange}
          value={email}
          required
          error={Boolean(emailError)}
          helperText={emailError}
        />
        <div className="logos-container">
          <div className="form-box" onClick={() => startApplication('rapidW7Completion', 'Rapid W7')}>
            <div className="form-content">
              <div className="form-title">{t('rapidW7Title')}</div>
              <div className="form-description">{t('rapidW7Description')}</div>
            </div>
            <div className="form-price">
              $9.99
            </div>
          </div>
          <div className="form-box" onClick={() => startApplication('onlineFormProcessing', 'Online Form Processing')}>
            <div className="form-content">
              <div className="form-title">{t('itinFormTitle')}</div>
              <div className="form-description">{t('itinFormDescription')}</div>
            </div>
            <div className="form-price">
              $299
            </div>
          </div>
          <div className="help-link">
            <a className="help-link-two" href="https://www.itin.support/how-it-works#steps" target="_blank" rel="noopener noreferrer">
              {t('learnMore')}
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
