import React, { useState, useContext, useEffect } from 'react';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';
import { handlePurchase } from '../../../hooks/helper'; // Import the helper
import FormReview from '../../components/FormReview'; // Ensure this path is correct

const TaxQuestion = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [fieldError, setFieldError] = useState('');
  const [showFormReview, setShowFormReview] = useState(false);

  useEffect(() => {
    if (formData.toggleQuestion === undefined) {
      setFormData(prev => ({
        ...prev,
        toggleQuestion: true,
      }));
    }
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      toggleQuestion: event.target.checked,
    });
  };

  const nextPage = () => {
    if (!formData.toggleQuestion) {
      setFieldError(t('taxFilingUploadError'));
      return;
    }

    if (formData.serviceType === 'rapidW7Completion') {
      setShowFormReview(true);
    } else {
      onNext();
    }
  };

  const handleCloseFormReview = () => setShowFormReview(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('filedTaxesLastThreeYears')}</span>
        <Tooltip title={t('infoToggleQuestion')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={formData.toggleQuestion}
            onChange={handleChange}
            name="toggleQuestion"
            color="primary"
          />
        }
        label={formData.toggleQuestion ? t('yes') : t('no')}
      />
      {fieldError && <p className="error-message">{fieldError}</p>}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
      
      {showFormReview && (
        <FormReview
          onEdit={handleCloseFormReview}
          onPurchase={() => handlePurchase(formData)}
          onClose={handleCloseFormReview}
        />
      )}
    </div>
  );
};

export default TaxQuestion;
