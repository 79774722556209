import React, { useEffect, useState, useContext } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { firestore } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import './PublicITIN.css';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { FormContext } from '../FormContext';
import Header from './components/Header';
import Footer from './components/Footer';
import defaultLogo from '../assets/textlogo.svg'; // Import the default logo

const PublicITIN = () => {
  const { userId } = useParams();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customization, setCustomization] = useState({ logoUrl: '', themeColor: '#000000', website: '', businessName: '' });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);
  const [email, setEmail] = useState(formData.email || '');
  const [emailError, setEmailError] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const subscriptionsRef = collection(firestore, 'users', userId, 'subscriptions');
      const q = query(subscriptionsRef, where('status', '==', 'active'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setIsSubscribed(true);
        const userDocRef = doc(firestore, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setCustomization({
            logoUrl: data.logoUrl || defaultLogo, // Use default logo if user logo is not set
            themeColor: data.themeColor || '#000000',
            website: data.website || '',
            businessName: data.businessName || '' // Add businessName to customization state
          });
        }
      } else {
        setIsSubscribed(false);
      }
      setLoading(false);
    };

    fetchSubscriptionStatus();
  }, [userId]);

  useEffect(() => {
    setFormData({ email: '' });
  }, [setFormData]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (emailError) {
      setEmailError('');
    }
  };

  const checkEmailExists = async (email) => {
    const emailsRef = collection(firestore, 'Emails');
    const querySnapshot = await getDocs(emailsRef);
    const emails = querySnapshot.docs.map((doc) => doc.data().email);
    return emails.includes(email);
  };

  const startApplication = async () => {
    if (email === '' || !validateEmail(email)) {
      setEmailError(t('invalidEmail'));
      return;
    }

    const emailExists = await checkEmailExists(email);

    if (!emailExists) {
      try {
        await addDoc(collection(firestore, 'Emails'), { email });
      } catch (error) {
        console.error('Error adding document:', error);
      }
    }

    setFormData({ ...formData, email, serviceType: 'plainApplication', userId });
    navigate(`/public/${userId}/form`);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('success') === 'true') {
      setShowSuccessMessage(true);
      params.delete('success');
      window.history.replaceState({}, '', `${window.location.pathname}`);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isSubscribed) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <div className="home-section" style={{ color: customization.themeColor }}>
        <img
          src={customization.logoUrl || defaultLogo} // Fallback to default logo
          alt="Logo"
          className="logo-home"
          onClick={() => window.location.href = customization.website}
          style={{ cursor: 'pointer' }}
        />
        <div className="languages-section">
          <select className="language-dropdown" value={i18n.language} onChange={changeLanguage}>
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="cn">中文</option>
            <option value="fn">Français</option>
            <option value="hn">हिन्दी भाषा</option>
          </select>
        </div>
        <h1 className="intro-text">{t('introText')}</h1>
        <h2 className="subtext" style={{ color: '#000000' }}>{t('caaSubtext')}</h2>
        <TextField
          label={t('provideEmailAddress')}
          variant="standard"
          className="home-field"
          style={{ marginBottom: '10px' }}
          onChange={handleEmailChange}
          value={email}
          required
          error={Boolean(emailError)}
          helperText={emailError}
        />
        <button
          className="start-application-button"
          onClick={startApplication}
          style={{ backgroundColor: customization.themeColor }}
        >
          {t('caaStartApplication')}
        </button>
        {showSuccessMessage && (
          <div className="success-message">
            <h2>{t('formSubmittedSuccessfully')}</h2>
            <p>{t('thankYouForYourSubmission')}</p>
          </div>
        )}
        {customization.businessName && ( // Display business name if available
          <div className="business-name" style={{ color: customization.themeColor }}>
            {customization.businessName}
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicITIN;
