import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../FormContext';
import './formReview.css';

const FormReview = ({ onEdit, onPurchase, onClose }) => {
  const { t } = useTranslation();
  const { formData } = useContext(FormContext);
  const handleContentClick = (e) => e.stopPropagation();

  const handleClose = () => {
    if (formData.serviceType === 'caaApplication') {
      onClose();
    } else {
      onPurchase();
    }
  };

  return (
    <div className="form-review-modal" onClick={handleClose}>
      <div className="form-review-content" onClick={handleContentClick}>
        <h2>{t('reviewYourInformation')}</h2>
        <section>
          <h3>{t('applicationDetails')}</h3>
          <p>{t('type')}: {formData.applicationType}</p>
          <p>{t('reason')}: {formData.reason}</p>
          {formData.reason === 'spouseOfUSCitizenResidentAlien' && (
            <>
              <p>{t('nameOfSpouse')}: {formData.spouseName}</p>
              <p>{t('spouseSsnItin')}: {formData.spouseSsnItin}</p>
            </>
          )}
          {formData.reason === 'dependentOfUSCitizenResidentAlien' && (
            <>
              <p>{t('nameOfDependent')}: {formData.dependentName}</p>
              <p>{t('dependentSsnItin')}: {formData.dependentSsnItin}</p>
            </>
          )}
        </section>
        <section>
          <h3>{t('taxInformation')}</h3>
          <p>{t('foreignTaxId')}: {formData.foreignTaxId || t('na')}</p>
          <p>{t('visaNumber')}: {formData.visaNumber || t('na')}</p>
          <p>{t('visaExpiration')}: {formData.visaExpirationDate || t('na')}</p>
          <p>{t('dateOfUsArrival')}: {formData.entryDate || t('na')}</p>
        </section>
        <section>
          <h3>{t('yourDetails')}</h3>
          <p>{t('fullName')}: {formData.firstName} {formData.lastName}</p>
          <p>{t('phoneNumber')}: {formData.phoneNumber}</p>
          <p>{t('dateOfBirth')}: {formData.dateOfBirth}</p>
          <p>{t('gender')}: {formData.gender}</p>
          <p>{t('countryOfOrigin')}: {formData.countryOfBirth}</p>
          <p>{t('usAddress')}: {formData.residentStreet}, {formData.residentCity} {formData.residentState}, {formData.residentZipCode}</p>
          <p>{t('foreignAddress')}: {formData.foreignStreet}, {formData.foreignCity} {formData.foreignCountry}, {formData.foreignPostalCode}</p>
        </section>
        <div className="form-review-actions">
          <button className="back-button" onClick={onEdit}>{t('edit')}</button>
          {formData.serviceType !== 'caaApplication' && (
            <button className="continue-button" onClick={onPurchase}>{t('purchase')}</button>
          )}
          {formData.serviceType === 'caaApplication' && (
            <button className="continue-button" onClick={handleClose}>{t('submit')}</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormReview;
