import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/textlogo.svg';

export default function OnboardingStepOne({ formData, setFormData, onNext }) {
  const auth = getAuth();
  const firestore = getFirestore();
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        const userRef = doc(firestore, 'users', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setFormData((prevState) => ({
            ...prevState,
            ...docSnap.data(),
          }));
        }
      };
      fetchUserData();
    }
  }, [userId, firestore, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      console.error("UserID is undefined, cannot update Firestore.");
      return;
    }

    try {
      const userRef = doc(firestore, 'users', userId);
      await updateDoc(userRef, formData);
      onNext();
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="onboarding-step-one">
      <img src={logo} alt="Logo" className="onboarding-logo" />
      <div className="onboarding-card">
        <h2>Certified Acceptance Agent (CAA) Onboarding Portal</h2>
        <p>Before you can start helping clients, we need to set up your professional profile and verify your credentials. This ensures a trustworthy and secure environment for both you and the clients you will assist.</p>
        <form onSubmit={handleSubmit}>
          <input
            className='form-field'
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            className='form-field'
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <input
            className='form-field'
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            className='form-field'
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="State"
            required
          />
          <input
            className='form-field'
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Country"
            required
          />
          <div className="submit-button-container">
            <button type="button" className='back-button' onClick={handleLogout}>Logout</button>
            <button className='continue-button' type="submit">Next</button>
          </div>
        </form>
      </div>
    </div>
  );
}
