import { useState, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';

export function useAuthentication() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const firestore = getFirestore();

  const updateUserData = useCallback((userData) => {
    console.log('Updating user data:', userData);
    setRole(userData.role);
    setProfilePictureUrl(userData.profilePictureUrl || '');
    setOnboardingCompleted(userData.onboardingCompleted === true);
    console.log('onboardingCompleted set to:', userData.onboardingCompleted === true);
  }, []);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        const userRef = doc(firestore, 'users', firebaseUser.uid);
        const unsubscribeFirestore = onSnapshot(userRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            console.log('User data fetched:', userData);
            updateUserData(userData);
          } else {
            setRole(null);
            setOnboardingCompleted(false);
            setProfilePictureUrl('');
          }
          setLoading(false);
        }, error => {
          console.error("Firestore subscription error:", error);
          setLoading(false);
        });

        return () => unsubscribeFirestore();
      } else {
        setUser(null);
        setRole(null);
        setOnboardingCompleted(false);
        setProfilePictureUrl('');
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, [auth, firestore, updateUserData]);

  const refreshUser = useCallback(async () => {
    if (auth.currentUser) {
      await auth.currentUser.reload();
      const userRef = doc(firestore, 'users', auth.currentUser.uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        console.log('Refreshed user data:', userData);
        updateUserData(userData);
      }
    }
  }, [auth, firestore, updateUserData]);

  return { user, role, onboardingCompleted, profilePictureUrl, loading, refreshUser };
}
