import React, { useEffect, useState, useContext } from 'react';
import { TextField, FormHelperText, Tooltip, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { FormContext } from '../../../FormContext';

const Address = ({ onNext, onBack, themeColor}) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [useResidentAsShipping, setUseResidentAsShipping] = useState(true);

  // Handler for address change
  const handleAddressChange = (event, field) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.residentStreet) {
      newErrors.residentStreet = t('residentStreetError');
      isValid = false;
    }
    if (!formData.residentCity) {
      newErrors.residentCity = t('residentCityError');
      isValid = false;
    }
    if (!formData.residentState) {
      newErrors.residentState = t('residentStateError');
      isValid = false;
    }
    if (!formData.residentZipCode) {
      newErrors.residentZipCode = t('residentZipCodeError');
      isValid = false;
    }
    

    if (!useResidentAsShipping) {
      if (!formData.shippingStreet) {
        newErrors.shippingStreet = t('shippingStreetError');
        isValid = false;
      }
      if (!formData.shippingCity) {
        newErrors.shippingCity = t('shippingCityError');
        isValid = false;
      }
      if (!formData.shippingState) {
        newErrors.shippingState = t('shippingStateError');
        isValid = false;
      }
      if (!formData.shippingZipCode) {
        newErrors.shippingZipCode = t('shippingZipCodeError');
        isValid = false;
      }
      if (!formData.shippingCountry) {
        newErrors.shippingCountry = t('shippingCountryError');
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const nextPage = () => {
    if (validateFields()) {
      onNext();
    }
  };
  
  return (
    <div className="input-container">
      <div className="header">
        <span className="sub-title">{t('residentAddress')}</span>
        <Tooltip title={t('infoResidentAddress')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <TextField
        id="residentStreet"
        label={t('street')}
        value={formData.residentStreet}
        onChange={(e) => handleAddressChange(e, 'residentStreet')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.residentStreet)}
      />
      {errors.residentStreet && <FormHelperText error>{errors.residentStreet}</FormHelperText>}
      
      <TextField
        id="residentCity"
        label={t('city')}
        value={formData.residentCity}
        onChange={(e) => handleAddressChange(e, 'residentCity')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.residentCity)}
      />
      {errors.residentCity && <FormHelperText error>{errors.residentCity}</FormHelperText>}

      <TextField
        id="residentState"
        label={t('state')}
        value={formData.residentState}
        onChange={(e) => handleAddressChange(e, 'residentState')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.residentState)}
      />
      {errors.residentState && <FormHelperText error>{errors.residentState}</FormHelperText>}

      <TextField
        id="residentZipCode"
        label={t('zipCode')}
        value={formData.residentZipCode}
        onChange={(e) => handleAddressChange(e, 'residentZipCode')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.residentZipCode)}
      />
      {errors.residentZipCode && <FormHelperText error>{errors.residentZipCode}</FormHelperText>}
   
   {/* Checkbox to Use Resident Address as Shipping Address */}
      <FormControlLabel
        control={
          <Checkbox
            checked={useResidentAsShipping}
            onChange={() => setUseResidentAsShipping(!useResidentAsShipping)}
          />
        }
        label="Use this address as my shipping address"
      />

      {/* If checkbox is unchecked, display Shipping Address Fields */}
      {!useResidentAsShipping && (
        <>
          <div className="header">
            <span className="sub-title">{t('shippingAddress')}</span>
          </div>
          <TextField
            id="shippingStreet"
            label={t('street')}
            value={formData.shippingStreet}
            onChange={(e) => handleAddressChange(e, 'shippingStreet')}
            variant="standard"
            className="input-field"
            required={!useResidentAsShipping}
          />
          {errors.shippingStreet && <FormHelperText error>{errors.shippingStreet}</FormHelperText>}

          <TextField
            id="shippingCity"
            label={t('city')}
            value={formData.shippingCity}
            onChange={(e) => handleAddressChange(e, 'shippingCity')}
            variant="standard"
            className="input-field"
            required={!useResidentAsShipping}
          />
          {errors.shippingCity && <FormHelperText error>{errors.shippingCity}</FormHelperText>}

          <TextField
            id="shippingState"
            label={t('state')}
            value={formData.shippingState}
            onChange={(e) => handleAddressChange(e, 'shippingState')}
            variant="standard"
            className="input-field"
            required={!useResidentAsShipping}
          />
          {errors.shippingState && <FormHelperText error>{errors.shippingState}</FormHelperText>}

          <TextField
            id="shippingZipCode"
            label={t('zipCode')}
            value={formData.shippingZipCode}
            onChange={(e) => handleAddressChange(e, 'shippingZipCode')}
            variant="standard"
            className="input-field"
            required={!useResidentAsShipping}
          />
          {errors.shippingZipCode && <FormHelperText error>{errors.shippingZipCode}</FormHelperText>}

          <TextField
            id="shippingCountry"
            label={t('country')}
            value={formData.shippingCountry}
            onChange={(e) => handleAddressChange(e, 'shippingCountry')}
            variant="standard"
            className="input-field"
            required={!useResidentAsShipping}
          />
          {errors.shippingCountry && <FormHelperText error>{errors.shippingCountry}</FormHelperText>}
        </>
      )}
      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default Address;