import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, setDoc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './component.css';
import ActionModal from './ActionModal'; 
import AssignCAAAgentModal from './functions/AssignCAAAgentModal';
import DeleteCustomerModal from './functions/DeleteCustomerModal'; 
import ViewAllIcon from '../../assets/icons/viewAll.svg';

const RecentSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const firestore = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsRef = collection(firestore, 'Submissions');
        const querySnapshot = await getDocs(query(
          submissionsRef, 
          where("paymentConfirmation", "==", true),
          where("caaAgent", "==", null)
        ));
        const fetchedSubmissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort submissions from newest to oldest
        const sortedSubmissions = fetchedSubmissions.sort((a, b) => {
          return b.timestamp.toDate() - a.timestamp.toDate();
        });
        
        setSubmissions(sortedSubmissions);
        setError(null);
      } catch (err) {
        console.error("Error fetching submissions: ", err);
        setError('Failed to fetch submissions');
      }
    };
  
    fetchSubmissions();
  }, [firestore]);   

  const handleNavigateToAllSubmissions = () => {
    navigate('/admin/submissions');
  };

  const handleNavigateToSubmission = (submissionId) => {
    navigate(`/admin/submission/${submissionId}`);
  };

  const handleOpenModal = (submissionId) => {
    setSelectedSubmission(submissionId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteSubmission = async (submissionId, reason) => {
    try {
      const submissionRef = doc(firestore, 'Submissions', submissionId);
      const submissionSnap = await getDoc(submissionRef);
  
      if (submissionSnap.exists()) {
        const submissionData = submissionSnap.data();
  
        await setDoc(doc(firestore, 'graveyard', submissionId), {
          ...submissionData,
          deletionReason: reason,
          deletedAt: new Date(),
        });
  
        await deleteDoc(submissionRef);
  
        setSubmissions(submissions.filter(sub => sub.id !== submissionId));
        console.log(`Deleted submission ${submissionId} and moved to graveyard.`);
      } else {
        console.error("Submission not found!");
      }
    } catch (error) {
      console.error("Error deleting submission:", error);
    }
    setShowDeleteModal(false);
  };

  const handleAssignAgent = async (agentId) => {
    try {
      const submissionRef = doc(firestore, 'Submissions', selectedSubmission);
      await updateDoc(submissionRef, { caaAgent: agentId });
      
      setSubmissions(submissions.filter(sub => sub.id !== selectedSubmission));
      
      console.log("Assigned Agent ID:", agentId, "for Submission ID:", selectedSubmission);
    } catch (error) {
      console.error("Error assigning agent:", error);
    }
    setShowAssignModal(false);
  };

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="recent-submissions-admin">
      <div className="table-header">
        <h2>Recent Submissions</h2>
        <img src={ViewAllIcon} alt="View All Submissions" onClick={handleNavigateToAllSubmissions} className="view-all-icon" />
      </div>
      {submissions.length > 0 ? (
      <table className="submissions-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Application Type</th>
            <th>Date of Birth</th>
            <th>Country of Birth</th>
            <th>Date of Submission</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission) => (
            <tr key={submission.id} onClick={() => handleOpenModal(submission.id)}>
              <td>{submission.firstName} {submission.lastName}</td>
              <td>{submission.applicationType}</td>
              <td>{submission.dateOfBirth}</td>
              <td>{submission.countryOfBirth}</td>
              <td>
                {submission.timestamp?.toDate().toLocaleDateString("en-US", {
                  year: 'numeric', month: 'long', day: 'numeric'
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      ) : (
        <div className="no-submissions-message">No Pending Applications</div>
      )}
      
      <ActionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onAssignCAA={() => setShowAssignModal(true)}
        onDeleteCustomer={() => setShowDeleteModal(true)}
        onViewApplication={() => handleNavigateToSubmission(selectedSubmission)}
      />
  
      <AssignCAAAgentModal
        open={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        onSubmit={handleAssignAgent}
        submissionId={selectedSubmission}
      />
  
      <DeleteCustomerModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDeleteSubmission}
        submissionId={selectedSubmission}
      />
    </div>
  );  
};  

export default RecentSubmissions;