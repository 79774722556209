import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/Logo.svg';
import HomeIcon from '../../assets/icons/Home.svg'; 
import Design from '../../assets/icons/Folder.svg';
import Inbox from '../../assets/icons/Mail.svg'
import SupportIcon from '../../assets/icons/setting.svg'; // Assume you have a support icon
import LogoutIcon from '../../assets/icons/logout.svg'; // Assume you have a logout icon
import { getAuth, signOut } from 'firebase/auth';
import './AdminHeader.css';

const SideNav = () => {
    const location = useLocation();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            // Redirect to login page or handle the user being logged out as needed
        } catch (error) {
            console.error('Logout Error:', error);
            // Handle any errors that occur during logout
        }
    };

    return (
        <div className="side-nav">
            <div className="icon-logo-container">
                <img src={Logo} alt='ITIN Support Logo' className="icon-logo" />
            </div>
            {/* Navigation Links */}
            <div className="nav-links">
              <Link to="/admin/" className={`nav-link ${location.pathname === "/" ? "selected" : ""}`}>
                  <img src={HomeIcon} alt='home' className="nav-icon" />
              </Link>
              <Link to="/admin/submissions" className={`nav-link ${location.pathname === "/playground" ? "selected" : ""}`}>
                  <img src={Design} alt='demo' className="nav-icon" />
              </Link>
              <Link to="/admin/messages" className={`nav-link ${location.pathname === "/installation" ? "selected" : ""}`}>
                  <img src={Inbox} alt='demo' className="nav-icon" />
              </Link>
            </div>
            <div className="support-logout">
                <Link to="/admin/settings" className="nav-link">
                    <img src={SupportIcon} alt='demo' className="nav-icon" />
                </Link>
                <Link className="nav-link" onClick={handleLogout}>
                    <img src={LogoutIcon} alt='demo' className="nav-icon" />
                </Link>
            </div>
        </div>
    );
}

export default SideNav;
