import React from 'react';
import Verify from './components/verify';
import SubmissionView from './components/recentSubmissions';
import './AdminHome.css';

const AdminHome = () => {
    return (
        <div className="admin-home">
            <div className="grid-container">
                <div className="grid-item grid-item-top-left">
                    <Verify />
                </div>
                <div className="grid-item grid-item-top-right">
                    {/* Add content for the top-right box here */}
                    <h2>Top Right Box</h2>
                    <p>Add your desired content here.</p>
                </div>
                <div className="grid-item grid-item-bottom">
                    <SubmissionView />
                </div>
            </div>
        </div>
    );
}

export default AdminHome;