import React, { useEffect } from 'react';
import SubmissionView from './components/recentSubmissions'; // Import Verify component
import StepGuide from './components/stepGuide'; // Import the new StepGuide component
import PaymentMethod from './components/PaymentMethod'; // Import the new StepGuide component
import './CAAHome.css'; // Make sure to create this CSS file

const CAAHome = () => {
    useEffect(() => {
        console.log("CAAHome rendered");
    }, []);

    return (
        <div className="caa-home">
            <div className="caa-container">
                <PaymentMethod />
                <SubmissionView />
                <StepGuide />
            </div>
        </div>
    );
}

export default CAAHome;
