import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDALYH7VeiqWjdk-qkp_AbbF3LeWCFQN7I",
  authDomain: "newitin-85fa5.firebaseapp.com",
  databaseURL: "https://newitin-85fa5-default-rtdb.firebaseio.com",
  projectId: "newitin-85fa5",
  storageBucket: "newitin-85fa5.appspot.com",
  messagingSenderId: "380162490725",
  appId: "1:380162490725:web:dc1fc1251f7d91c9c0218c",
  measurementId: "G-T2N7M5GGER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);  // Changed line
const storage = getStorage(app);

export { auth, firestore, storage, analytics };  // Export firestore instead of db
