// src/components/Subscribed.js
import React, { useState, useEffect, useRef } from 'react';
import { auth, firestore, storage } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Subscribed = () => {
  const [businessName, setBusinessName] = useState('');
  const [website, setWebsite] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [themeColor, setThemeColor] = useState('#000000');
  const [publicLink, setPublicLink] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [currentLogo, setCurrentLogo] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState('');

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchStyles = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        setBusinessName(data.businessName || '');
        setWebsite(data.website || '');
        setLogoUrl(data.logoUrl || '');
        setThemeColor(data.themeColor || '#000000');
        setCurrentLogo(data.logoUrl || '');
      }

      setPublicLink(`https://app.itin.support/public/${userId}`); // Adjust this URL to your actual public form URL
    };

    fetchStyles();
  }, []);

  const handleLogoUpload = async (file) => {
    if (file) {
      const userId = auth.currentUser?.uid;
      const storageRef = ref(storage, `logos/${userId}/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      setLogoUrl(url);
      setFileName(file.name);
      setHasChanges(true);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      handleLogoUpload(file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      handleLogoUpload(file);
    }
  };

  const handleSave = async () => {
    if (!isValidWebsite(website)) {
      setWebsiteError('Please enter a valid website URL.');
      return;
    }

    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const userDocRef = doc(firestore, 'users', userId);
    await setDoc(userDocRef, {
      businessName,
      website,
      logoUrl,
      themeColor
    }, { merge: true });

    setHasChanges(false);
    setCurrentLogo(logoUrl);
  };

  const isValidWebsite = (url) => {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return regex.test(url);
  };

  const colorOptions = ['#FF5733', '#33FF57', '#3357FF', '#F3FF33', '#FF33F3', '#000000'];

  return (
    <div className="subscribed-container">
      <h1>Welcome to Premium</h1>
      <p>Manage your public ITIN application form:</p>
      
      <div className="form-group">
        <label htmlFor="businessName">Business Name:</label>
        <input
          type="text"
          id="businessName"
          value={businessName}
          onChange={(e) => { setBusinessName(e.target.value); setHasChanges(true); }}
        />
      </div>

      <div className="form-group">
        <label htmlFor="website">Website:</label>
        <input
          type="text"
          id="website"
          value={website}
          onChange={(e) => { 
            setWebsite(e.target.value); 
            setHasChanges(true); 
            setWebsiteError(''); 
          }}
        />
        {websiteError && <p className="error-text">{websiteError}</p>}
      </div>

      <div 
        className={`form-group ${isDragging ? 'dragging' : ''}`} 
        onDragOver={handleDragOver} 
        onDragLeave={handleDragLeave} 
        onDrop={handleDrop}
      >
        <label htmlFor="logoUrl">Logo:</label>
        <input
          type="file"
          id="logoUrl"
          accept="image/png, image/jpeg, image/jpg, image/svg+xml"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {currentLogo && <img src={currentLogo} alt="Current Logo" className="logo-preview" />}
        {logoUrl && logoUrl !== currentLogo && <img src={logoUrl} alt="New Logo" className="logo-preview" />}
        {fileName && <p>Selected file: {fileName}</p>}
        <p>Drag and drop your logo here or click to upload.</p>
      </div>

      <div className="form-group">
        <label htmlFor="themeColor">Theme Color:</label>
        <div className="color-options">
          {colorOptions.map(color => (
            <div
              key={color}
              className="color-swatch"
              style={{ backgroundColor: color }}
              onClick={() => { setThemeColor(color); setHasChanges(true); }}
            />
          ))}
        </div>
        <input
          type="color"
          id="themeColor"
          value={themeColor}
          onChange={(e) => { setThemeColor(e.target.value); setHasChanges(true); }}
        />
      </div>

      {hasChanges && <button className="save-button" onClick={handleSave}>Save Changes</button>}

      <p>
        <a href={publicLink} target="_blank" rel="noopener noreferrer">
          <button className="continue-button">Go to Public Form</button>
        </a>
      </p>
    </div>
  );
};

export default Subscribed;
