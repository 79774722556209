import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';
import OtherReason from './OtherReason';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

const Reasoning = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [reasonError, setReasonError] = useState('');
  const [otherReasonComplete, setOtherReasonComplete] = useState(false);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, reason: value });
  };

  const handleNext = () => {
    if (!formData.reason) {
      setReasonError(t('reasonError'));
    } else {
      setReasonError('');
      onNext();
    }
  };

  return (
    <div className="switcher">
      <div className="header">
        <span className="sub-title">{t('reason')}</span>
        <Tooltip title={t('reasonInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div className="sub-sub-title">{t('reasonTypo')}</div>
      <select
        value={formData.reason || ''}  // Use value prop here
        onChange={handleInputChange}
        className="custom-select"
      >
        <option value="" disabled>{t('selectValidReason')}</option>
        <option value="usResidentAlienTaxReturn">{t('usResidentAlienTaxReturn')}</option>
        <option value="nonUSResident">{t('nonUSResident')}</option>
        <option value="dependentOfUSCitizenResidentAlien">{t('dependentOfUSCitizenResidentAlien')}</option>
        <option value="spouseOfUSCitizenResidentAlien">{t('spouseOfUSCitizenResidentAlien')}</option>
        <option value="other">{t('other')}</option>
      </select>
      {reasonError && <p className="error-message">{reasonError}</p>}

      {formData.reason === 'other' && (
        <OtherReason
          formData={formData}
          handleInputChange={handleInputChange}
          onCompletion={setOtherReasonComplete}
        />
      )}

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={handleNext}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default Reasoning;
