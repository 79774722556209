import React from 'react';
import './Submissions.css'; // Make sure to create this CSS file
import Submissions from './Admin'; // Import Verify component

const SubmissionsPage = () => {
    return (
        <div className="submissions-page">
            <Submissions />
        </div>
    );
}

export default SubmissionsPage;
