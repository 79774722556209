import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/Logo.svg';
import HomeIcon from '../../assets/icons/Home.svg';
import SubmissionsIcon from '../../assets/icons/Folder.svg';
import MessagesIcon from '../../assets/icons/Mail.svg';
import SettingsIcon from '../../assets/icons/setting.svg';
import PremiumIcon from '../../assets/icons/link.svg';  // Add a premium icon if you have one
import LogoutIcon from '../../assets/icons/logout.svg';
import { getAuth, signOut } from 'firebase/auth';
import './AdminHeader.css';

const CAASideNav = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/login'); // Redirect to login page
        } catch (error) {
            console.error('Logout Error:', error);
        }
    };

    return (
        <div className="side-nav">
            <div className="icon-logo-container">
                <img src={Logo} alt='ITIN Support Logo' className="icon-logo" />
            </div>
            <div className="nav-links">
                <Link to="/CAA" className={`nav-link ${location.pathname === "/CAA" ? "selected" : ""}`}>
                    <img src={HomeIcon} alt='Home' className="nav-icon" />
                </Link>
                <Link to="/CAA/submissions" className={`nav-link ${location.pathname.includes("/CAA/submissions") ? "selected" : ""}`}>
                    <img src={SubmissionsIcon} alt='Submissions' className="nav-icon" />
                </Link>
                <Link to="/CAA/messages" className={`nav-link ${location.pathname.includes("/CAA/messages") ? "selected" : ""}`}>
                    <img src={MessagesIcon} alt='Messages' className="nav-icon" />
                </Link>
                <Link to="/CAA/premium" className={`nav-link ${location.pathname.includes("/CAA/premium") ? "selected" : ""}`}>
                    <img src={PremiumIcon} alt='Premium' className="nav-icon" />
                </Link>
            </div>
            <div className="support-logout">
                <Link to="/CAA/settings" className="nav-link">
                    <img src={SettingsIcon} alt='Settings' className="nav-icon" />
                </Link>
                <Link className="nav-link" onClick={handleLogout}>
                    <img src={LogoutIcon} alt='Logout' className="nav-icon" />
                </Link>
            </div>
        </div>
    );
}

export default CAASideNav;
