import React, { useState, useContext } from 'react';
import { TextField, FormHelperText, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../../../FormContext';
import { handlePurchase } from '../../../hooks/helper'; // Import the helper
import FormReview from '../../components/FormReview'; // Ensure this path is correct

const ForeignAddress = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [showFormReview, setShowFormReview] = useState(false);

  const handleAddressChange = (event, field) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.foreignStreet) {
      newErrors.foreignStreet = t('residentStreetError');
      isValid = false;
    }
    if (!formData.foreignCity) {
      newErrors.foreignCity = t('residentCityError');
      isValid = false;
    }
    if (!formData.foreignCountry) {
      newErrors.foreignCountry = t('errorCountry');
      isValid = false;
    }
    if (!formData.foreignPostalCode) {
      newErrors.foreignPostalCode = t('errorPostalCode');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const nextPage = () => {
    if (validateFields()) {
      if (formData.serviceType === 'rapidW7Completion') {
        setShowFormReview(true);
      } else {
        onNext();
      }
    }
  };

  return (
    <div className="input-container">
      <div className="header">
        <span className="sub-title">{t('foreignAddress')}</span>
        <Tooltip title={t('infoForeignAddress')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <TextField
        id="foreignStreet"
        label={t('street')}
        value={formData.foreignStreet || ''}
        onChange={(e) => handleAddressChange(e, 'foreignStreet')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.foreignStreet)}
      />
      <FormHelperText error>{errors.foreignStreet}</FormHelperText>

      <TextField
        id="foreignCity"
        label={t('city')}
        value={formData.foreignCity || ''}
        onChange={(e) => handleAddressChange(e, 'foreignCity')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.foreignCity)}
      />
      <FormHelperText error>{errors.foreignCity}</FormHelperText>

      <TextField
        id="foreignCountry"
        label={t('country')}
        value={formData.foreignCountry || ''}
        onChange={(e) => handleAddressChange(e, 'foreignCountry')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.foreignCountry)}
      />
      <FormHelperText error>{errors.foreignCountry}</FormHelperText>

      <TextField
        id="foreignPostalCode"
        label={t('postalCode')}
        value={formData.foreignPostalCode || ''}
        onChange={(e) => handleAddressChange(e, 'foreignPostalCode')}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.foreignPostalCode)}
      />
      <FormHelperText error>{errors.foreignPostalCode}</FormHelperText>

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
      
      {showFormReview && (
        <FormReview
          onEdit={() => setShowFormReview(false)}
          onPurchase={() => handlePurchase(formData)}
          onClose={() => setShowFormReview(false)}
        />
      )}
    </div>
  );
};

export default ForeignAddress;
