import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './RecentSubmissions.css';

const RecentSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const navigate = useNavigate();
  const firestore = getFirestore();
  const auth = getAuth();
  const currentUserID = auth.currentUser?.uid;

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsRef = collection(firestore, 'Submissions');
        const submissionsQuery = query(submissionsRef, where("PaymentConfirmation", "==", true), where("caaAgent", "==", currentUserID));
        const querySnapshot = await getDocs(submissionsQuery);
        const fetchedSubmissions = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date(0), // Convert to Date object or use epoch if null
            timeSince: getTimeSince(doc.data().timestamp)
          }))
          .filter(submission => !['Approved', 'Declined', 'Mailed'].includes(submission.status || 'Pending'))
          .sort((a, b) => b.timestamp - a.timestamp); // Sort from newest to oldest

        setSubmissions(fetchedSubmissions);
      } catch (err) {
        console.error("Error fetching submissions: ", err);
      }
    };
    fetchSubmissions();
  }, [firestore, currentUserID]);

  const getTimeSince = (timestamp) => {
    if (!timestamp) return { text: 'N/A', urgency: '' };
    const now = new Date();
    const submissionDate = timestamp.toDate();
    const differenceInMs = now - submissionDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

    let text, urgency;
    if (differenceInDays < 1) {
      text = `${Math.round(differenceInDays * 24)} hours ago`;
    } else {
      text = `${Math.round(differenceInDays)} days ago`;
    }

    if (differenceInDays <= 10) {
      urgency = 'urgent-green';
    } else if (differenceInDays <= 30) {
      urgency = 'urgent-yellow';
    } else {
      urgency = 'urgent-red';
    }

    return { text, urgency };
  };

  return (
    <div className='recent-submissions'>
      <h2>Recent Submissions</h2>
      <table className="submissions-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Application Type</th>
            <th>Submitted</th>
          </tr>
        </thead>
        <tbody>
          {submissions.length > 0 ? (
            submissions.map((submission) => (
              <tr 
                key={submission.id} 
                onClick={() => navigate(`/CAA/submission/${submission.id}`)}
                className="clickable-row"
              >
                <td>{submission.firstName} {submission.lastName}</td>
                <td>{submission.applicationType}</td>
                <td className={submission.timeSince.urgency}>{submission.timeSince.text}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="no-submissions">No recent submissions</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentSubmissions;