import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, query, where, onSnapshot, addDoc, orderBy } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import SendIcon from '../assets/icons/send.svg'; 
import AttachmentIcon from '../assets/icons/folder-add.svg'; // Import the attachment icon
import './CAAMessages.css';

const CAAMessages = () => {
    const [activeConversations, setActiveConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const fileInputRef = useRef(null);
    const [messages, setMessages] = useState([]); // Add state to hold messages
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState(null);
    const auth = getAuth();
    const firestore = getFirestore();
    const user = auth.currentUser;

    useEffect(() => {
        if (!user) return;
        const submissionsRef = collection(firestore, 'Submissions');
        const q = query(submissionsRef, where('caaAgent', '==', user.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const conversations = [];
            querySnapshot.forEach((doc) => {
                conversations.push({ id: doc.id, ...doc.data() });
            });
            setActiveConversations(conversations);
            if (conversations.length > 0) {
                setCurrentConversation(conversations[0]);
            }
        });

        return () => unsubscribe();
    }, [user, firestore]);

    // Fetch messages for the current conversation
    useEffect(() => {
        if (currentConversation && currentConversation.id) {
            const messagesRef = collection(firestore, 'Messages');
            const q = query(messagesRef, where('submissionId', '==', currentConversation.id), orderBy('timestamp'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const fetchedMessages = [];
                querySnapshot.forEach((doc) => {
                    fetchedMessages.push(doc.data());
                });
                setMessages(fetchedMessages);
                console.log("Fetched Messages:", fetchedMessages); // Move logging here
            });
    
            return () => unsubscribe();
        }
    }, [firestore, currentConversation]);

    useEffect(() => {
        // Scroll to the bottom of the messages div
        const messagesContainer = document.querySelector(".messages");
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [messages]);
    

    const handleFileSelect = (event) => {
        const fileToUpload = event.target.files[0];
        if (fileToUpload) {
          // uploadFile(fileToUpload);
          setFile(fileToUpload);
        }
    };

    // Function to handle file uploads
    const uploadFile = async (file) => {
        if (!file) return;
        setUploading(true);
    
        const storageRef = ref(getStorage(), `chat_uploads/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Handle progress updates here if needed
            },
            (error) => {
                console.error('Upload error:', error);
                setUploading(false);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                // After upload, send a message with the file URL
                await sendMessage(downloadURL, 'file');
                setUploading(false);
                setFile(null); // Clear the file after sending
            }
        );
    };

    // Modified sendMessage function to handle both text and file messages
    const sendMessage = async (content, type = 'text') => {
        if (!content.trim()) return;
        await addDoc(collection(firestore, 'Messages'), {
            submissionId: currentConversation.id,
            message: type === 'text' ? content : '',
            file: type === 'file' ? content : '',
            senderId: user.uid,
            timestamp: new Date(),
            type: type, // 'text' or 'file'
        });
        setNewMessage('');
    };

    const handleSendMessage = () => {
        if (file) {
          uploadFile(file);
        } else {
          sendMessage(newMessage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleAttachmentClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent default behavior (such as opening the file)
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
          const fileToUpload = files[0];
          // You could call the uploadFile function here
          // uploadFile(fileToUpload);
          // Or set it to state and let the user confirm before uploading
          setFile(fileToUpload);
        }
    };

    return (
        <div className="caa-messages" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className="caa-recent-contacts">
                {activeConversations.map((conversation) => (
                    <div 
                        key={conversation.id} 
                        className={`caa-contact-item ${currentConversation && currentConversation.id === conversation.id ? 'active' : ''}`} 
                        onClick={() => setCurrentConversation(conversation)}
                    >
                        {conversation.firstName} {conversation.lastName}
                    </div>
                ))}
            </div>
            <div className="caa-message-history">
        {currentConversation && (
          <>
            <h2 className="caa-message-title">{currentConversation.firstName} {currentConversation.lastName}</h2>
            <div className="caa-message">
              {messages.map((msg, index) => (
                <div key={index} className={`caa-message ${msg.senderId === user.uid ? 'caa-sent' : 'caa-received'}`}>
                  {msg.message}
                  {msg.type === 'file' && <a href={msg.file} target="_blank" rel="noopener noreferrer">Download File</a>}
                </div>
              ))}
            </div>
            <div className="caa-message-box">
              <textarea 
                className="caa-textarea"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type a message..."
              />
              {file && <span>{file.name}</span>}
              <input
                type="file"
                onChange={handleFileSelect}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <button className="attachment-button" onClick={handleAttachmentClick}>
                <img src={AttachmentIcon} alt="Attach file" />
              </button>
              <button className="caa-button" onClick={handleSendMessage}>
                <img src={SendIcon} alt="Send" />
              </button>
            </div>
            {uploading && <div>Uploading...</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default CAAMessages;