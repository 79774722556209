import { useEffect, useState } from 'react';
import { getFirestore, query, collection, where, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export const useFetchNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser; // Ensure you're correctly getting the current user

  useEffect(() => {
    if (!user) return;

    // First, fetch the user document to get the role
    const fetchUserRole = async () => {
      const userRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) return;

      const userData = userDoc.data();
      const role = userData.role; // Assuming the role is stored under the 'role' field

      let q;
      if (role === 'superAdmin') {
        // Query for notifications intended for super-admin
        q = query(collection(firestore, 'notifications'), where('adminId', '==', user.uid));
      } else if (role === 'caaAgent') {
        // Query for notifications intended for this CAA agent
        q = query(collection(firestore, 'notifications'), where('agentId', '==', user.uid), where('read', '==', false));
      } else {
        // No relevant notifications for other roles or unidentified roles
        return;
      }

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedNotifications = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotifications(fetchedNotifications);
      });

      return () => unsubscribe();
    };

    fetchUserRole();
  }, [user]);

  return notifications;
};

export default useFetchNotifications;
