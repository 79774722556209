import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthentication } from './hooks/useAuthentication';
import Loader from './pages/components/Loader'

const ProtectedRoute = ({ redirectTo = '/login' }) => {
  const { user, role, onboardingCompleted, loading } = useAuthentication();
  const location = useLocation();

  if (loading) {
    return <Loader />;
  }

  if (!user || !role) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  if (role === 'CAA' && !onboardingCompleted) {
    return <Navigate to="/CAA/onboarding" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
