import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Chip } from '@mui/material';
import { getFirestore, collection, query, where, getDocs, doc, setDoc, addDoc } from 'firebase/firestore';

const AssignCAAAgentModal = ({ open, onClose, submissionId }) => {
  const [agents, setAgents] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchAgents = async () => {
      const agentsRef = collection(firestore, 'users');
      const q = query(agentsRef, where("caaStatus", "==", true));
      const querySnapshot = await getDocs(q);
      const fetchedAgents = [];
      querySnapshot.forEach((doc) => {
        fetchedAgents.push({ id: doc.id, ...doc.data() });
      });
      setAgents(fetchedAgents);
    };

    if (open) {
      fetchAgents();
    }
  }, [firestore, open]);

  const handleAssignAgent = async (agentId) => {
    try {
      const submissionRef = doc(firestore, 'Submissions', submissionId);
      await setDoc(submissionRef, { caaAgent: agentId }, { merge: true });
  
      // Create a notification for the assigned CAA agent
      const notificationRef = collection(firestore, `users/${agentId}/notifications`);
      const notification = {
        title: "New Submission Assigned",
        message: `You have been assigned a new submission: ${submissionId}`,
        createdAt: new Date(),
        read: false
      };
      await addDoc(notificationRef, notification);
  
      console.log(`Submission ${submissionId} assigned to CAA Agent ${agentId} and notification created.`);
      onClose(); // Close the modal after successful assignment
    } catch (error) {
      console.error("Error assigning CAA Agent or creating notification:", error);
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign CAA Agent</DialogTitle>
      <DialogContent>
        {agents.map((agent) => (
          <Chip key={agent.id} label={`${agent.firstName} ${agent.lastName}`} onClick={() => handleAssignAgent(agent.id)} style={{ margin: 4 }} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignCAAAgentModal;
