import React from 'react';
import logo from '../../logo/text-logo.png';
import './Tab.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-row">
                <div className="footer-right">
                    <img src={logo} alt="ITIN Support Text Logo" className="footerlogo" />
                </div>
            </div>
            <div className="footer-row">
                <div className="footer-left">
                    © 2024 itin.support. All rights reserved
                </div>
                <div className="footer-right">
                    <a href="https://www.itin.support/terms-of-service" target="_blank" rel="noopener noreferrer">Terms Of Use</a>
                    <a href="https://www.itin.support/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
