// src/components/Unsubscribed.js
import React from 'react';
import { getCheckoutUrl } from './stripePayment'; // Update the path as needed

const Unsubscribed = () => {
  const handleSubscribe = async () => {
    try {
      const currentUrl = window.location.href;
      const checkoutUrl = await getCheckoutUrl('price_1Pb7SbJu54yrucEtx9Ad7K87', currentUrl);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <div className="unsubscribed-container">
      <h1 className="unsubscribed-title">Unlock Premium Features</h1>
      <div className="unsubscribed-content">
        <h2 className="unsubscribed-subtitle">Get Your Own Custom ITIN Application Form</h2>
        <ul className="unsubscribed-benefits">
          <li>Streamline your ITIN application process</li>
          <li>Accept applications online 24/7</li>
          <li>Reduce paperwork and manual data entry</li>
          <li>Improve customer experience with a user-friendly interface</li>
          <li>Secure and compliant data collection</li>
          <li>Faster processing times for applications</li>
        </ul>
        <p className="unsubscribed-cta">
          Upgrade now to start accepting ITIN applications online and simplify your workflow!
        </p>
      </div>
      <button className="continue-button" onClick={handleSubscribe}>
        Subscribe Now
      </button>
    </div>
  );
};

export default Unsubscribed;