import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, FormHelperText, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { FormContext } from '../../../FormContext';

const formatResidentOrSpouseSsnITINNumber = (number) => {
  const digitsOnly = number.replace(/-/g, '');
  if (digitsOnly.length > 5) {
    return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3, 5)}-${digitsOnly.slice(5, 9)}`;
  } else if (digitsOnly.length > 3) {
    return `${digitsOnly.slice(0, 3)}-${digitsOnly.slice(3)}`;
  }
  return digitsOnly;
};

const Spouse = ({ onNext, onBack, themeColor }) => {
  const { t } = useTranslation();
  const { formData, setFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});

  const handleNameChange = (event) => {
    setFormData({ ...formData, residentOrSpouseName: event.target.value });
  };

  const handleResidentOrSpouseSsnITINChange = (event) => {
    const inputNumber = event.target.value.replace(/[^0-9-]/g, '');
    const formattedNumber = formatResidentOrSpouseSsnITINNumber(inputNumber);
    setFormData({ ...formData, residentOrSpouseSsnITIN: formattedNumber });
  };

  useEffect(() => {
    const errors = {
      name: !formData.residentOrSpouseName ? t('nameSpouseError') : null,
      ssnITIN: !formData.residentOrSpouseSsnITIN ? t('ssnITINError') : null,
    };
    setErrors(errors);
    // Note: Ensure you handle completion correctly
  }, [formData, t]);

  const nextPage = () => {
    if (!formData.residentOrSpouseName || !formData.residentOrSpouseSsnITIN) {
      // Handle error
      return;
    }
  
    onNext();
  };  

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      nextPage();
    }
  };

  return (
    <div className="input-container" onKeyDown={handleKeyPress}>
      <div className="header">
        <span className="sub-title">{t('spouseInfo')}</span>
        <Tooltip title={t('infoSpouseInfo')} arrow>
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>

      <TextField
        id="nameOfUSCitizenResidentAli-multiline"
        label={t('nameOfSpouse')}
        multiline
        maxRows={4}
        value={formData.residentOrSpouseName}
        onChange={handleNameChange}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.name)}
      />
      {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}

      <TextField
        id="residentOrSpouseSsnITIN-multiline"
        label={t('spouseSsnITIN')}
        multiline
        maxRows={4}
        value={formData.residentOrSpouseSsnITIN}
        onChange={handleResidentOrSpouseSsnITINChange}
        variant="standard"
        className="input-field"
        required
        error={Boolean(errors.ssnITIN)}
      />
      {errors.ssnITIN && <FormHelperText error>{errors.ssnITIN}</FormHelperText>}

      <div className="button-group">
        <button className="back-button" onClick={onBack}>
          {t('backButton')}
        </button>
        <button className="continue-button" onClick={nextPage} style={{ backgroundColor: themeColor }}>
          {t('continueButton')}
        </button>
      </div>
    </div>
  );
};

export default Spouse;
