import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../logo/full-logo.png';
import '../Form.css'; // Assuming you have a CSS file for styling

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  return (
    <div className="terms">
      <img src={logo} alt="ITIN Express Logo" className="logo-big" />
      <div className="terms-container">
        <h1 className="page-title">Terms and Conditions</h1>
        <div className="scrollable-text">
          <p>
            Welcome to itin.support! These terms and conditions ("Terms") govern your access to and use of our website and services. By accessing, using, or browsing our website, you acknowledge that you have read, understood, and agree to be bound by these Terms. Throughout these Terms, "we," "our," or "the company" refers to itin.support, and "you" or "applicant" refers to our service users.
          </p>

          <h2 className="sub-terms">1. Personal Information and Privacy</h2>
          <p>
            By using our services, you consent to provide personal information necessary for your Individual Taxpayer Identification Number (ITIN) application. We commit to securing your information in accordance with our Privacy Policy.
          </p>

          <h2 className="sub-terms">2. Applicant Responsibilities</h2>
          <p>
            You agree to provide true, accurate, and complete information for your ITIN application. Any falsification, omission, or conduct violating eligibility requirements may result in the cancellation of your application without refund.
          </p>

          <h2 className="sub-terms">3. Use of our Website</h2>
          <p>
            Our website and services are intended for personal, non-commercial use. We are an independent entity and are not affiliated with the United States Government or any governmental body.
          </p>

          <h2 className="sub-terms">4. Service Content</h2>
          <p>
            We reserve the right to modify the content, service offerings, or these Terms at any time for various reasons, including technical updates, security enhancements, or changes in service scope.
          </p>

          <h2 className="sub-terms">5. Our Service</h2>
          <p>
            Our service facilitates the processing of ITIN applications, including form completion assistance, document verification, and support throughout the application process. The final decision on the application is made by the relevant governmental authority.
          </p>

          <h2 className="sub-terms">Fees and Payment</h2>
          <p>
            Our service fee is $49.99, which is non-refundable. This fee covers the processing and handling of your ITIN application. Additional charges may apply for courier or governmental fees.
          </p>
          <p>
            You may request a full refund or be routed to another CAA agent for assistance if your application has not been mailed out. After your application is mailed with a valid tracking number provided by a CAA agent, refunds will not be available as the agent will not receive payment until this point.
          </p>

          <h2 className="sub-terms">Limitation of Liability</h2>
          <p>
            We process applications based on the information provided by you. We are not responsible for any delays, errors, or additional costs incurred due to actions by governmental authorities or third-party service providers.
          </p>

          <h2 className="sub-terms">Governing Law and Jurisdiction</h2>
          <p>
            These Terms are governed by the laws of the jurisdiction in which our company is registered, without regard to its conflict of law provisions.
          </p>

          <h2 className="sub-terms">Third-Party Responsibility</h2>
          <p>
            We are not liable for errors or delays caused by third parties involved in the ITIN application process. Any disputes with third parties should be resolved directly with them.
          </p>

          <p>
            Acceptance of Terms: By using our website and services, you acknowledge and agree to these Terms. If you disagree with any part of these Terms, you must not use our website and services.
          </p>
          <p>
            For any inquiries or assistance, please contact us at benjamin@itin.support.
          </p>
        </div>
      </div>

      <button className="back-button" onClick={goBack}>
        Return
      </button>
    </div>
  );
};

export default TermsAndConditions;
