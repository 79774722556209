import React from 'react';
import logo from '../../logo/logo.png';
import { useTranslation } from 'react-i18next';
import './Tab.css';

const Header = () => {
    const { t } = useTranslation();

    return (
        <header className="topheader">
            <div className="logo-container">
                <a href='/'>
                    <img src={logo} alt="ITIN Support Logo" className="logo" />
                </a>
            </div>
            <div className="center-container"></div>
            <div className="menu-container">
                <a href="https://www.itin.support/blog">{t('blog')}</a>
                <a href="https://youtu.be/ehkaoxhcAiY">{t('demoVideo')}</a>
            </div>
        </header>
    );
};

export default Header;
